import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { getLatestListings } from './utils';
import { useConfiguration } from '../../context/configurationContext';
import { ListingCardSimple, IconCustomArrow } from '../../components';

import css from './LatestListingsSection.module.css';

const MODAL_BREAKPOINT = 768;

export const LatestListingsSection = props => {

  const config = useConfiguration();
  const {isMapVariant} = props;
  const [latestListings, setLatestListings] = useState([]);

  useEffect(() => {

    getLatestListings({
      page: 1,
      perPage: 10,
    }, config)
      .then(resp => {
        setLatestListings(resp);
      })
      .catch(e => console.log(e));
  }, []);


  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const items = latestListings.map(l => (
    <ListingCardSimple
      className={css.listingCard}
      key={l.id.uuid}
      listing={l}
      renderSizes={cardRenderSizes(isMapVariant)}
    />
  ));

  const prevButton = () => {
    return <IconCustomArrow direction="left" />
  }

  const nextButton = () => {
    return <IconCustomArrow direction="right" />
  }

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  return latestListings && latestListings.length > 0 && (
    <div className={css.root}>
      <div className={css.itemWrapper}>
        <AliceCarousel
          infinite={true}
          items={items}
          disableDotsControls={true}
          paddingLeft={isMobileLayout ? 74 : 0}
          paddingRight={isMobileLayout ? 74 : 0}
          renderPrevButton={() => prevButton()}
          renderNextButton={() => nextButton()}
          responsive={{
            0: { items: 1 },
            768: { items: 2 },
            1024: { items: 4 }
          }}
        />
      </div>
    </div>
  );
}

export default LatestListingsSection;