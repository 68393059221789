import React from 'react';
import classNames from 'classnames';

import css from './IconPlusMinus.module.css';

const IconPlusMinus = props => {
  const { rootClassName, className, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === "minus") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        fill="none"
        viewBox="0 0 20 21"
      >
        <path
          stroke="#201F22"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.938"
          d="M4.037 10.4h11.667"
        ></path>
      </svg>
    );
  } else if (type === "plus") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        fill="none"
        viewBox="0 0 20 21"
      >
        <path
          fill="#201F22"
          fillRule="evenodd"
          d="M10.469 4.567a.469.469 0 00-.938 0V9.93H4.167a.469.469 0 100 .938h5.364v5.364a.469.469 0 10.938 0V10.87h5.365a.469.469 0 000-.938h-5.365V4.567z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  } else {
    return null
  }
};


export default IconPlusMinus;
