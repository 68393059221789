import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestion.module.css';

const IconQuestion = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#201F22"
        fillRule="evenodd"
        d="M10.313 3.082a6.875 6.875 0 100 13.75 6.875 6.875 0 000-13.75zM2.188 9.957a8.125 8.125 0 1116.25 0 8.125 8.125 0 01-16.25 0zm8.125 3.541c.346 0 .625.28.625.625v.009a.625.625 0 01-1.25 0v-.009c0-.345.28-.625.625-.625zm.068-8.357a2.792 2.792 0 00-2.232 1.105.625.625 0 00.996.755 1.542 1.542 0 111.785 2.37 1.875 1.875 0 00-1.24 1.871.625.625 0 001.248-.07.625.625 0 01.416-.626l.016-.005a2.793 2.793 0 00-.99-5.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconQuestion.defaultProps = { className: null };

const { string } = PropTypes;

IconQuestion.propTypes = { className: string };

export default IconQuestion;
