import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocationPin.module.css';

const IconLocationPin = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="#201F22"
        d="M5.625 7.5A1.877 1.877 0 007.5 5.625 1.877 1.877 0 005.625 3.75 1.877 1.877 0 003.75 5.625c0 1.034.841 1.875 1.875 1.875zm0-2.5a.626.626 0 11-.001 1.251A.626.626 0 015.625 5z"
      ></path>
      <path
        fill="#201F22"
        d="M11.25 5.625A5.631 5.631 0 005.625 0 5.631 5.631 0 000 5.625c0 1.227.388 2.393 1.123 3.372L4.124 13c.353.47.914.751 1.501.751.587 0 1.148-.28 1.5-.75l3.002-4.003a5.578 5.578 0 001.123-3.372zM9.127 8.247L6.126 12.25a.621.621 0 01-.501.251.62.62 0 01-.5-.25L2.122 8.246a4.337 4.337 0 01-.873-2.622A4.38 4.38 0 015.625 1.25 4.38 4.38 0 0110 5.625c0 .954-.302 1.861-.873 2.622z"
      ></path>
    </svg>
  );
};

IconLocationPin.defaultProps = { className: null };

const { string } = PropTypes;

IconLocationPin.propTypes = { className: string };

export default IconLocationPin;
