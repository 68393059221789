import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.module.css';

const IconShare = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#201F22"
        fillRule="evenodd"
        d="M14.74 3.038a1.875 1.875 0 00-1.713 2.638.659.659 0 01.049.101 1.875 1.875 0 101.664-2.739zm-3.125 1.875c0 .27.034.531.098.78L7.218 8.01a3.125 3.125 0 100 3.808l4.495 2.316a3.128 3.128 0 003.027 3.905 3.125 3.125 0 10-2.478-5.029l-4.495-2.315a3.13 3.13 0 000-1.561l4.495-2.316a3.125 3.125 0 10-.647-1.904zm-5.162 5.765a1.868 1.868 0 00-.047-1.626 1.875 1.875 0 100 1.723.629.629 0 01.047-.097zm6.574 3.473a.615.615 0 00.049-.101 1.875 1.875 0 11-.05.101z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconShare.defaultProps = { className: null };

const { string } = PropTypes;

IconShare.propTypes = { className: string };

export default IconShare;
