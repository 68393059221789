import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCustomArrow.module.css';

const IconCustomArrow = props => {
  const { className, rootClassName, direction } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (direction === "left") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="22"
        fill="none"
        viewBox="0 0 12 22"
      >
        <path
          stroke="#201F22"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 1L1 11l10 10"
        ></path>
      </svg>
    )
  } else if (direction === "right") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="22"
        fill="none"
        viewBox="0 0 12 22"
      >
        <path
          stroke="#201F22"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1l10 10L1 21"
        ></path>
      </svg>
    )
  } else if (direction === "down") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="7"
        fill="none"
        viewBox="0 0 11 7"
      >
        <path stroke="#201F22" d="M1 1l4.5 5L10 1"></path>
      </svg>
    )
  }
  else {
    return null
  }
};

IconCustomArrow.defaultProps = { className: null };

const { string } = PropTypes;

IconCustomArrow.propTypes = {
  className: string,
  direction: string,
};

export default IconCustomArrow;
