import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFilters.module.css';

const IconFilters = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#201F22"
        d="M11.333 3.333a2 2 0 114.001 0 2 2 0 01-4 0zM1.333 4h8a.667.667 0 100-1.333h-8a.667.667 0 000 1.333zm4 2a2 2 0 00-1.88 1.333h-2.12a.667.667 0 000 1.334h2.12A2 2 0 105.333 6zm9.334 1.333H9.332a.667.667 0 000 1.334h5.333a.667.667 0 100-1.334zm-8 4.667H1.333a.666.666 0 100 1.333h5.333a.667.667 0 100-1.333zm8 0h-2.12a2 2 0 100 1.333h2.12a.667.667 0 100-1.333z"
      ></path>
    </svg>
  );

};

IconFilters.defaultProps = { className: null };

const { string } = PropTypes;

IconFilters.propTypes = { className: string };

export default IconFilters;
