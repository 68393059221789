import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowCarousel.module.css';



const IconArrowCarousel = props => {
  const { className, rootClassName, direction } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (direction) {
    case "left":
      return (
        <svg
          className={classes}
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          fill="none"
          viewBox="0 0 44 44"
        >
          <rect width="43" height="43" x="0.5" y="0.5" fill="#fff" rx="21.5"></rect>
          <rect
            width="43"
            height="43"
            x="0.5"
            y="0.5"
            stroke="#ECECEC"
            rx="21.5"
          ></rect>
          <path
            stroke="#1D1D1B"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 22h14M15 22l6 6M15 22l6-6"
          ></path>
        </svg>
      );
    case "right":
      return (
        <svg
          className={classes}
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          fill="none"
          viewBox="0 0 44 44"
        >
          <rect width="43" height="43" x="0.5" y="0.5" fill="#fff" rx="21.5"></rect>
          <rect
            width="43"
            height="43"
            x="0.5"
            y="0.5"
            stroke="#ECECEC"
            rx="21.5"
          ></rect>
          <path
            stroke="#1D1D1B"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 22h14M23 28l6-6M23 16l6 6"
          ></path>
        </svg>
      );

    default:
      return null
  }
};

IconArrowCarousel.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowCarousel.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowCarousel;
