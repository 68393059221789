export const categoryOptions = [
  // ===================================================================
  // Jewellery & Accessories
  // ===================================================================
  {
    key: "jewelleryAndAccessories",
    label: "Jewellery & Accessories",
  },
  {
    key: "jewelry",
    label: "Jewelry",
    parentCategories: ["jewelleryAndAccessories"]
  },
  {
    key: "anklets",
    label: "Anklets",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "bodyJewelry",
    label: "Body Jewelry",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "bracelets",
    label: "Bracelets",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "broochesAndLapelPins",
    label: "Brooches & Lapel Pins",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "charmsAndPendants",
    label: "Charms & Pendants",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "earrings",
    label: "Earrings",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "jewelrySets",
    label: "Jewelry Sets",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "necklaces",
    label: "Necklaces",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "rings",
    label: "Rings",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "watchAccessories",
    label: "Watch Accessories",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "watchBands",
    label: "Watch Bands",
    parentCategories: ["watchAccessories", "jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "watchStickersAndDecals",
    label: "Watch Stickers & Decals",
    parentCategories: ["watchAccessories", "jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "watchWinders",
    label: "Watch Winders",
    parentCategories: ["watchAccessories", "jewelry", "jewelleryAndAccessories"]
  },
  {
    key: "watches",
    label: "Watches",
    parentCategories: ["jewelry", "jewelleryAndAccessories"]
  },
  // ===================================================================
  // Food & Drink
  // ===================================================================
  {
    key: "foodAndDrink",
    label: "Food & Drink",
  },
  {
    key: "beverages",
    label: "Beverages",
    parentCategories: ["foodAndDrink"]
  },
  {
    key: "alcoholicBeverages",
    label: "Alcoholic Beverages",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "beer",
    label: "Beer",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "bitters",
    label: "Bitters",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "cocktailMixes",
    label: "Cocktail Mixes",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "frozenCocktailMixes",
    label: "Frozen Cocktail Mixes",
    parentCategories: ["cocktailMixes", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "shelfStableCocktailMixes",
    label: "Shelf-stable Cocktail Mixes",
    parentCategories: ["cocktailMixes", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "flavoredAlcoholicBeverages",
    label: "Flavored Alcoholic Beverages",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "hardCider",
    label: "Hard Cider",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "liquorAndSpirits",
    label: "Liquor & Spirits",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "absinthe",
    label: "Absinthe",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "brandy",
    label: "Brandy",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "gin",
    label: "Gin",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "liqueurs",
    label: "Liqueurs",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "rum",
    label: "Rum",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "shochuAndSoju",
    label: "Shochu & Soju",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "shochu",
    label: "shochu",
    parentCategories: ["shochuAndSoju", "liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "soju",
    label: "soju",
    parentCategories: ["shochuAndSoju", "liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "tequila",
    label: "Tequila",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "vodka",
    label: "Vodka",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "whiskey",
    label: "Whiskey",
    parentCategories: ["liquorAndSpirits", "alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "wine",
    label: "Wine",
    parentCategories: ["alcoholicBeverages", "beverages", "foodAndDrink"]
  },
  {
    key: "buttermilk",
    label: "Buttermilk",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "coffee",
    label: "Coffee",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "eggnog",
    label: "Eggnog",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "fruitFlavoredDrinks",
    label: "Fruit Flavored Drinks",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "hotChocolate",
    label: "Hot Chocolate",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "juice",
    label: "Juice",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "milk",
    label: "Milk",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "nonDairyMilk",
    label: "Non-Dairy Milk",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "powderedBeverageMixes",
    label: "Powdered Beverage Mixes",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "soda",
    label: "Soda",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "sportsAndEnergyDrinks",
    label: "Sports & Energy Drinks",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "teaAndInfusions",
    label: "Tea & Infusions",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "vinegarDrinks",
    label: "Vinegar Drinks",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "water",
    label: "Water",
    parentCategories: ["beverages", "foodAndDrink"]
  },
  {
    key: "carbonatedWater",
    label: "Carbonated Water",
    parentCategories: ["water", "beverages", "foodAndDrink"]
  },
  {
    key: "flavoredCarbonatedWater",
    label: "Flavored Carbonated Water",
    parentCategories: ["carbonatedWater", "water", "beverages", "foodAndDrink"]
  },
  {
    key: "unflavoredCarbonatedWater",
    label: "Unflavored Carbonated Water",
    parentCategories: ["carbonatedWater", "water", "beverages", "foodAndDrink"]
  },
  {
    key: "distilledWater",
    label: "Distilled Water",
    parentCategories: ["water", "beverages", "foodAndDrink"]
  },
  {
    key: "flatMineralWater",
    label: "Flat Mineral Water",
    parentCategories: ["water", "beverages", "foodAndDrink"]
  },
  {
    key: "springWater",
    label: "Spring Water",
    parentCategories: ["water", "beverages", "foodAndDrink"]
  },
  {
    key: "foodItems",
    label: "Food Items",
    parentCategories: ["foodAndDrink"]
  },
  {
    key: "bakery",
    label: "Bakery",
    parentCategories: ["foodItems", "foodAndDrink"]
  },

  {
    key: "bagels",
    label: "Bagels",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakeryAssortments",
    label: "Bakery Assortments",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "breadsAndBuns",
    label: "Breads & Buns",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "cakesAndDessertBars",
    label: "Cakes & Dessert Bars",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "coffeeCakes",
    label: "Coffee Cakes",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookies",
    label: "Cookies",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "cupcakes",
    label: "Cupcakes",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "donuts",
    label: "Donuts",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "fudge",
    label: "Fudge",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "iceCreamCones",
    label: "Ice Cream Cones",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "muffins",
    label: "Muffins",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "pastriesAndScones",
    label: "Pastries & Scones",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "piesAndTarts",
    label: "Pies & Tarts",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "tacoShellsAndTostadas",
    label: "Taco Shells & Tostadas",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },
  {
    key: "tortillasAndWraps",
    label: "Tortillas & Wraps",
    parentCategories: ["bakery", "foodItems", "foodAndDrink"]
  },

  {
    key: "candiedAndChocolateCoveredFruit",
    label: "Candied & Chocolate Covered Fruit",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "candyAndChocolate",
    label: "Candy & Chocolate",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "chocolates",
    label: "Chocolates",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "cocoaPowder",
    label: "Cocoa Powder",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "cocoa",
    label: "Cocoa",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "condimentsAndSauces",
    label: "Condiments & Sauces",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "cocktailSauce",
    label: "Cocktail Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "currySauce",
    label: "Curry Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "dessertToppings",
    label: "Dessert Toppings",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },

  {
    key: "fruitToppings",
    label: "Fruit Toppings",
    parentCategories: ["dessertToppings", "condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "iceCreamSyrup",
    label: "Ice Cream Syrup",
    parentCategories: ["dessertToppings", "condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "fishSauce",
    label: "Fish Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "gravy",
    label: "Gravy",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "honey",
    label: "Honey",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "horseradishSauce",
    label: "Horseradish Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "hotSauce",
    label: "Hot Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "ketchup",
    label: "Ketchup",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "marinadesAndGrillingSauces",
    label: "Marinades & Grilling Sauces",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "mayonnaise",
    label: "Mayonnaise",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "mustard",
    label: "Mustard",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "olivesAndCapers",
    label: "Olives & Capers",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "pastaSauce",
    label: "Pasta Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "pickledFruitsAndVegetables",
    label: "Pickled Fruits & Vegetables",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "pizzaSauce",
    label: "Pizza Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "relishAndChutney",
    label: "Relish & Chutney",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "saladDressing",
    label: "Salad Dressing",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "sataySauce",
    label: "Satay Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "soySauce",
    label: "Soy Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "sweetAndSourSauces",
    label: "Sweet and Sour Sauces",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "syrup",
    label: "Syrup",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "tahini",
    label: "Tahini",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "tartarSauce",
    label: "Tartar Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "whiteAndCreamSauces",
    label: "White & Cream Sauces",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "worcestershireSauce",
    label: "Worcestershire Sauce",
    parentCategories: ["condimentsAndSauces", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookingAndBakingIngredients",
    label: "Cooking & Baking Ingredients",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "bakingChips",
    label: "Baking Chips",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakingChocolate",
    label: "Baking Chocolate",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakingFlavorsAndExtracts",
    label: "Baking Flavors & Extracts",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakingMixes",
    label: "Baking Mixes",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakingPowder",
    label: "Baking Powder",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "bakingSoda",
    label: "Baking Soda",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "batterAndCoatingMixes",
    label: "Batter & Coating Mixes",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "beanPaste",
    label: "Bean Paste",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "breadCrumbs",
    label: "Bread Crumbs",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cannedAndDryMilk",
    label: "Canned & Dry Milk",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookieDecoratingKits",
    label: "Cookie Decorating Kits",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookingOils",
    label: "Cooking Oils",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookingStarch",
    label: "Cooking Starch",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookingWine",
    label: "Cooking Wine",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cornSyrup",
    label: "Corn Syrup",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "dough",
    label: "Dough",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "breadAndPastryDough",
    label: "Bread & Pastry Dough",
    parentCategories: ["dough", "cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "cookieAndBrownieDough",
    label: "Cookie & Brownie Dough",
    parentCategories: ["dough", "cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "pieCrusts",
    label: "Pie Crusts",
    parentCategories: ["dough", "cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "edibleBakingDecorations",
    label: "Edible Baking Decorations",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "eggReplacers",
    label: "Egg Replacers",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "flossSugar",
    label: "Floss Sugar",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "flour",
    label: "Flour",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "foodColoring",
    label: "Food Coloring",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "frostingAndIcing",
    label: "Frosting & Icing",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "lemonAndLimeJuice",
    label: "Lemon & Lime Juice",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "marshmallows",
    label: "Marshmallows",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "meal",
    label: "Meal",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "molasses",
    label: "Molasses",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "pieAndPastryFillings",
    label: "Pie & Pastry Fillings",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "shorteningAndLard",
    label: "Shortening & Lard",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "starterCultures",
    label: "Starter Cultures",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "sugarAndSweeteners",
    label: "Sugar & Sweeteners",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "tapiocaPearls",
    label: "Tapioca Pearls",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "tomatoPaste",
    label: "Tomato Paste",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "unflavoredGelatin",
    label: "Unflavored Gelatin",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "vinegar",
    label: "Vinegar",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "waffleAndPancakeMixes",
    label: "Waffle & Pancake Mixes",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "yeast",
    label: "Yeast",
    parentCategories: ["cookingAndBakingIngredients", "foodItems", "foodAndDrink"]
  },
  {
    key: "dairyProducts",
    label: "Dairy Products",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "butterAndMargarine",
    label: "Butter & Margarine",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "cheese",
    label: "Cheese",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "coffeeCreamer",
    label: "Coffee Creamer",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "cottageCheese",
    label: "Cottage Cheese",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "cream",
    label: "Cream",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "sourCream",
    label: "Sour Cream",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "whippedCream",
    label: "Whipped Cream",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "yogurt",
    label: "Yogurt",
    parentCategories: ["dairyProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "dipsAndSpreads",
    label: "Dips & Spreads",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "appleButter",
    label: "Apple Butter",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "cheeseDipsAndSpreads",
    label: "Cheese Dips & Spreads",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "creamCheese",
    label: "Cream Cheese",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "guacamole",
    label: "Guacamole",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "hummus",
    label: "Hummus",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "jamsAndJellies",
    label: "Jams & Jellies",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "nutButters",
    label: "Nut Butters",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "salsa",
    label: "Salsa",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "tapenade",
    label: "Tapenade",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "vegetableDip",
    label: "Vegetable Dip",
    parentCategories: ["dipsAndSpreads", "foodItems", "foodAndDrink"]
  },
  {
    key: "foodGiftBaskets",
    label: "Food Gift Baskets",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "frozenDessertsAndNovelties",
    label: "Frozen Desserts & Novelties",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "iceCreamAndFrozenYogurt",
    label: "Ice Cream & Frozen Yogurt",
    parentCategories: ["frozenDessertsAndNovelties", "foodItems", "foodAndDrink"]
  },
  {
    key: "iceCreamNovelties",
    label: "Ice Cream Novelties",
    parentCategories: ["frozenDessertsAndNovelties", "foodItems", "foodAndDrink"]
  },
  {
    key: "icePops",
    label: "Ice Pops",
    parentCategories: ["frozenDessertsAndNovelties", "foodItems", "foodAndDrink"]
  },
  {
    key: "fruitsAndVegetables",
    label: "Fruits & Vegetables",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "cannedAndJarredFruits",
    label: "Canned & Jarred Fruits",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cannedAndJarredVegetables",
    label: "Canned & Jarred Vegetables",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cannedAndPreparedBeans",
    label: "Canned & Prepared Beans",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "driedFruits",
    label: "Dried Fruits",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "driedVegetables",
    label: "Dried Vegetables",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "dryBeans",
    label: "Dry Beans",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "freshAndFrozenFruits",
    label: "Fresh & Frozen Fruits",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "apples",
    label: "Apples",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "atemoyas",
    label: "Atemoyas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "avocados",
    label: "Avocados",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "babacos",
    label: "Babacos",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "bananas",
    label: "Bananas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "berries",
    label: "Berries",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "breadfruit",
    label: "Breadfruit",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cactusPears",
    label: "Cactus Pears",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cherimoyas",
    label: "Cherimoyas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "citrusFruits",
    label: "Citrus Fruits",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "grapefruits",
    label: "Grapefruits",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "kumquats",
    label: "Kumquats",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "lemons",
    label: "Lemons",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "limequats",
    label: "Limequats",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "limes",
    label: "Limes",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "oranges",
    label: "Oranges",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "tangelos",
    label: "Tangelos",
    parentCategories: ["citrusFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "coconuts",
    label: "Coconuts",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "dates",
    label: "Dates",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "feijoas",
    label: "Feijoas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "figs",
    label: "Figs",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "fruitMixes",
    label: "Fruit Mixes",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "grapes",
    label: "Grapes",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "guavas",
    label: "Guavas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "homelyFruits",
    label: "Homely Fruits",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "kiwis",
    label: "Kiwis",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "longan",
    label: "Longan",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "loquats",
    label: "Loquats",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "lychees",
    label: "Lychees",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "madroao",
    label: "MadroÃ±o",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "mamey",
    label: "Mamey",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "mangosteens",
    label: "Mangosteens",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "melons",
    label: "Melons",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "papayas",
    label: "Papayas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "passionFruit",
    label: "Passion Fruit",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "pears",
    label: "Pears",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "persimmons",
    label: "Persimmons",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "physalis",
    label: "Physalis",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "pineapples",
    label: "Pineapples",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "pitahayas",
    label: "Pitahayas",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "pomegranates",
    label: "Pomegranates",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "quince",
    label: "Quince",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "rambutans",
    label: "Rambutans",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sapodillo",
    label: "Sapodillo",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sapote",
    label: "Sapote",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "soursops",
    label: "Soursops",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "starfruits",
    label: "Starfruits",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "stoneFruits",
    label: "Stone Fruits",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "apricots",
    label: "Apricots",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cherries",
    label: "Cherries",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "mangoes",
    label: "Mangoes",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "peachesAndNectarines",
    label: "Peaches & Nectarines",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "plumcots",
    label: "Plumcots",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "plums",
    label: "Plums",
    parentCategories: ["stoneFruits", "freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sugarApples",
    label: "Sugar Apples",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "tamarindo",
    label: "Tamarindo",
    parentCategories: ["freshAndFrozenFruits", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "freshAndFrozenVegetables",
    label: "Fresh & Frozen Vegetables",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "arracachas",
    label: "Arracachas",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "artichokes",
    label: "Artichokes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "asparagus",
    label: "Asparagus",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "beans",
    label: "Beans",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "beets",
    label: "Beets",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "borage",
    label: "Borage",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "broccoli",
    label: "Broccoli",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "brusselSprouts",
    label: "Brussel Sprouts",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cabbage",
    label: "Cabbage",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cactusLeaves",
    label: "Cactus Leaves",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cardoon",
    label: "Cardoon",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "carrots",
    label: "Carrots",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cauliflower",
    label: "Cauliflower",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "celery",
    label: "Celery",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "celeryRoots",
    label: "Celery Roots",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "corn",
    label: "Corn",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "cucumbers",
    label: "Cucumbers",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "eggplants",
    label: "Eggplants",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "fennelBulbs",
    label: "Fennel Bulbs",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "fiddleheadFerns",
    label: "Fiddlehead Ferns",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "gaiChoy",
    label: "Gai Choy",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "gaiLan",
    label: "Gai Lan",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "garlic",
    label: "Garlic",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "gingerRoot",
    label: "Ginger Root",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "goboRoot",
    label: "Gobo Root",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "greens",
    label: "Greens",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "arugula",
    label: "Arugula",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "beetGreens",
    label: "Beet Greens",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "bokChoy",
    label: "Bok Choy",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "chard",
    label: "Chard",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "chicory",
    label: "Chicory",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "choySum",
    label: "Choy Sum",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "kale",
    label: "Kale",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "lettuce",
    label: "Lettuce",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "onChoy",
    label: "On Choy",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "saladMixes",
    label: "Salad Mixes",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "spinach",
    label: "Spinach",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "yuChoy",
    label: "Yu Choy",
    parentCategories: ["greens", "freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "horseradishRoot",
    label: "Horseradish Root",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "jicama",
    label: "Jicama",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "kohlrabi",
    label: "Kohlrabi",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "leeks",
    label: "Leeks",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "lotusRoots",
    label: "Lotus Roots",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "malangas",
    label: "Malangas",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "mushrooms",
    label: "Mushrooms",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "okra",
    label: "Okra",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "onions",
    label: "Onions",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "parsleyRoots",
    label: "Parsley Roots",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "parsnips",
    label: "Parsnips",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "peas",
    label: "Peas",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "peppers",
    label: "Peppers",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "potatoes",
    label: "Potatoes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "radishes",
    label: "Radishes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "rhubarb",
    label: "Rhubarb",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "shallots",
    label: "Shallots",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sprouts",
    label: "Sprouts",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "squashesAndGourds",
    label: "Squashes & Gourds",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sugarCane",
    label: "Sugar Cane",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sunchokes",
    label: "Sunchokes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "sweetPotatoes",
    label: "Sweet Potatoes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "tamarillos",
    label: "Tamarillos",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "taroRoot",
    label: "Taro Root",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "tomatoes",
    label: "Tomatoes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "turnipsAndRutabagas",
    label: "Turnips & Rutabagas",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "vegetableMixes",
    label: "Vegetable Mixes",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "waterChestnuts",
    label: "Water Chestnuts",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "watercress",
    label: "Watercress",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "wheatgrass",
    label: "Wheatgrass",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "yams",
    label: "Yams",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "yucaRoot",
    label: "Yuca Root",
    parentCategories: ["freshAndFrozenVegetables", "fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "fruitSauces",
    label: "Fruit Sauces",
    parentCategories: ["fruitsAndVegetables", "foodItems", "foodAndDrink"]
  },
  {
    key: "grainsRiceAndCereal",
    label: "Grains, Rice & Cereal",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "amaranth",
    label: "Amaranth",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "barley",
    label: "Barley",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "buckwheat",
    label: "Buckwheat",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "cerealAndGranola",
    label: "Cereal & Granola",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "couscous",
    label: "Couscous",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "millet",
    label: "Millet",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "oatsGritsAndHotCereal",
    label: "Oats, Grits & Hot Cereal",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "quinoa",
    label: "Quinoa",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "rice",
    label: "Rice",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "rye",
    label: "Rye",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "wheat",
    label: "Wheat",
    parentCategories: ["grainsRiceAndCereal", "foodItems", "foodAndDrink"]
  },
  {
    key: "meatSeafoodAndEggs",
    label: "Meat, Seafood & Eggs",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "eggs",
    label: "Eggs",
    parentCategories: ["meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "eggWhites",
    label: "Egg Whites",
    parentCategories: ["eggs", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "liquidAndFrozenEggs",
    label: "Liquid & Frozen Eggs",
    parentCategories: ["eggs", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "preparedEggs",
    label: "Prepared Eggs",
    parentCategories: ["eggs", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "wholeEggs",
    label: "Whole Eggs",
    parentCategories: ["eggs", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "meat",
    label: "Meat",
    parentCategories: ["meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "cannedMeats",
    label: "Canned Meats",
    parentCategories: ["meat", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "freshAndFrozenMeats",
    label: "Fresh & Frozen Meats",
    parentCategories: ["meat", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "lunchAndDeliMeats",
    label: "Lunch & Deli Meats",
    parentCategories: ["meat", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "seafood",
    label: "Seafood",
    parentCategories: ["meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "cannedSeafood",
    label: "Canned Seafood",
    parentCategories: ["seafood", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "freshAndFrozenSeafood",
    label: "Fresh & Frozen Seafood",
    parentCategories: ["seafood", "meatSeafoodAndEggs", "foodItems", "foodAndDrink"]
  },
  {
    key: "nutsAndSeeds",
    label: "Nuts & Seeds",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "pastaAndNoodles",
    label: "Pasta & Noodles",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "preparedFoods",
    label: "Prepared Foods",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "preparedAppetizersAndSideDishes",
    label: "Prepared Appetizers & Side Dishes",
    parentCategories: ["preparedFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "preparedMealsAndEntrances",
    label: "Prepared Meals & Entrances",
    parentCategories: ["preparedFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "seasoningsAndSpices",
    label: "Seasonings & Spices",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "herbsAndSpices",
    label: "Herbs & Spices",
    parentCategories: ["seasoningsAndSpices", "foodItems", "foodAndDrink"]
  },
  {
    key: "msg",
    label: "MSG",
    parentCategories: ["seasoningsAndSpices", "foodItems", "foodAndDrink"]
  },
  {
    key: "pepper",
    label: "Pepper",
    parentCategories: ["seasoningsAndSpices", "foodItems", "foodAndDrink"]
  },
  {
    key: "salt",
    label: "Salt",
    parentCategories: ["seasoningsAndSpices", "foodItems", "foodAndDrink"]
  },
  {
    key: "snackFoods",
    label: "Snack Foods",
    parentCategories: ["foodItems", "foodAndDrink"]
  },

  {
    key: "breadsticks",
    label: "Breadsticks",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "cerealAndGranolaBars",
    label: "Cereal & Granola Bars",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "cerealBars",
    label: "Cereal Bars",
    parentCategories: ["cerealAndGranolaBars", "snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "granolaBars",
    label: "Granola Bars",
    parentCategories: ["cerealAndGranolaBars", "snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "cheesePuffs",
    label: "Cheese Puffs",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "chips",
    label: "Chips",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "crackers",
    label: "Crackers",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "croutons",
    label: "Croutons",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "fruitSnacks",
    label: "Fruit Snacks",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "jerky",
    label: "Jerky",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "popcorn",
    label: "Popcorn",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "porkRinds",
    label: "Pork Rinds",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "pretzels",
    label: "Pretzels",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "puddingAndGelatinSnacks",
    label: "Pudding & Gelatin Snacks",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "puffedRiceCakes",
    label: "Puffed Rice Cakes",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "saladToppings",
    label: "Salad Toppings",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "sesameSticks",
    label: "Sesame Sticks",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "snackCakes",
    label: "Snack Cakes",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "stickyRiceCakes",
    label: "Sticky Rice Cakes",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "trailAndSnackMixes",
    label: "Trail & Snack Mixes",
    parentCategories: ["snackFoods", "foodItems", "foodAndDrink"]
  },
  {
    key: "soupsAndBroths",
    label: "Soups & Broths",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "tofuSoyAndVegetarianProducts",
    label: "Tofu, Soy & Vegetarian Products",
    parentCategories: ["foodItems", "foodAndDrink"]
  },
  {
    key: "cheeseAlternatives",
    label: "Cheese Alternatives",
    parentCategories: ["tofuSoyAndVegetarianProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "meatAlternatives",
    label: "Meat Alternatives",
    parentCategories: ["tofuSoyAndVegetarianProducts", "foodItems", "foodAndDrink"]
  },
  {
    key: "tofu",
    label: "Tofu",
    parentCategories: ["tofuSoyAndVegetarianProducts", "foodItems", "foodAndDrink"]
  },

  // ===================================================================
  // Home & Living
  // ===================================================================
  {
    key: "homeAndLiving",
    label: "Home & Decor",
  },
  {
    key: "bathroomAccessories",
    label: "Bathroom Accessories",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "bathCaddies",
    label: "Bath Caddies",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "bathMatsAndRugs",
    label: "Bath Mats & Rugs",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "bathPillows",
    label: "Bath Pillows",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "bathroomAccessoryMounts",
    label: "Bathroom Accessory Mounts",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "bathroomAccessorySets",
    label: "Bathroom Accessory Sets",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "facialTissueHolders",
    label: "Facial Tissue Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "handDryerAccessories",
    label: "Hand Dryer Accessories",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "handDryers",
    label: "Hand Dryers",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "medicineCabinets",
    label: "Medicine Cabinets",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "robeHooks",
    label: "Robe Hooks",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "safetyGrabBars",
    label: "Safety Grab Bars",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "showerCurtainRings",
    label: "Shower Curtain Rings",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "showerCurtains",
    label: "Shower Curtains",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "showerRods",
    label: "Shower Rods",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "soapAndLotionDispensers",
    label: "Soap & Lotion Dispensers",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "soapDishesAndHolders",
    label: "Soap Dishes & Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "toiletBrushReplacementHeads",
    label: "Toilet Brush Replacement Heads",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "toiletBrushesAndHolders",
    label: "Toilet Brushes & Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "toiletPaperHolders",
    label: "Toilet Paper Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "toothbrushHolders",
    label: "Toothbrush Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "towelRacksAndHolders",
    label: "Towel Racks & Holders",
    parentCategories: ["bathroomAccessories", "homeAndLiving"]
  },
  {
    key: "decor",
    label: "Decor",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "addressSigns",
    label: "Address Signs",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "artificialFlora",
    label: "Artificial Flora",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "artificialFood",
    label: "Artificial Food",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "artwork",
    label: "Artwork",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeTapestries",
    label: "Decorative Tapestries",
    parentCategories: ["artwork", "decor", "homeAndLiving"]
  },
  {
    key: "postersPrintsAndVisualArtwork",
    label: "Posters, Prints, & Visual Artwork",
    parentCategories: ["artwork", "decor", "homeAndLiving"]
  },
  {
    key: "sculpturesAndStatues",
    label: "Sculptures & Statues",
    parentCategories: ["artwork", "decor", "homeAndLiving"]
  },
  {
    key: "backrestPillows",
    label: "Backrest Pillows",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "baskets",
    label: "Baskets",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "birdAndWildlifeFeederAccessories",
    label: "Bird & Wildlife Feeder Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "birdAndWildlifeFeeders",
    label: "Bird & Wildlife Feeders",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "birdFeeders",
    label: "Bird Feeders",
    parentCategories: ["birdAndWildlifeFeeders", "decor", "homeAndLiving"]
  },
  {
    key: "butterflyFeeders",
    label: "Butterfly Feeders",
    parentCategories: ["birdAndWildlifeFeeders", "decor", "homeAndLiving"]
  },
  {
    key: "squirrelFeeders",
    label: "Squirrel Feeders",
    parentCategories: ["birdAndWildlifeFeeders", "decor", "homeAndLiving"]
  },
  {
    key: "birdAndWildlifeHouseAccessories",
    label: "Bird & Wildlife House Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "birdAndWildlifeHouses",
    label: "Bird & Wildlife Houses",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "batHouses",
    label: "Bat Houses",
    parentCategories: ["birdAndWildlifeHouses", "decor", "homeAndLiving"]
  },
  {
    key: "birdhouses",
    label: "Birdhouses",
    parentCategories: ["birdAndWildlifeHouses", "decor", "homeAndLiving"]
  },
  {
    key: "butterflyHouses",
    label: "Butterfly Houses",
    parentCategories: ["birdAndWildlifeHouses", "decor", "homeAndLiving"]
  },
  {
    key: "birdBaths",
    label: "Bird Baths",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "bookends",
    label: "Bookends",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "cardboardCutouts",
    label: "Cardboard Cutouts",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "chairAndSofaCushions",
    label: "Chair & Sofa Cushions",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "clockParts",
    label: "Clock Parts",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "clocks",
    label: "Clocks",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "alarmClocks",
    label: "Alarm Clocks",
    parentCategories: ["clocks", "decor", "homeAndLiving"]
  },
  {
    key: "deskAndShelfClocks",
    label: "Desk & Shelf Clocks",
    parentCategories: ["clocks", "decor", "homeAndLiving"]
  },
  {
    key: "floorAndGrandfatherClocks",
    label: "Floor & Grandfather Clocks",
    parentCategories: ["clocks", "decor", "homeAndLiving"]
  },
  {
    key: "wallClocks",
    label: "Wall Clocks",
    parentCategories: ["clocks", "decor", "homeAndLiving"]
  },
  {
    key: "coatAndHatRacks",
    label: "Coat & Hat Racks",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeBells",
    label: "Decorative Bells",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeBottles",
    label: "Decorative Bottles",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeBowls",
    label: "Decorative Bowls",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeJars",
    label: "Decorative Jars",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativePlaques",
    label: "Decorative Plaques",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativePlates",
    label: "Decorative Plates",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "decorativeTrays",
    label: "Decorative Trays",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "doorMats",
    label: "Door Mats",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "dreamcatchers",
    label: "Dreamcatchers",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "driedFlowers",
    label: "Dried Flowers",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "ecospheres",
    label: "Ecospheres",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "figurines",
    label: "Figurines",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "finials",
    label: "Finials",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "flagAndWindsockAccessories",
    label: "Flag & Windsock Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "flagAndWindsockPoleLights",
    label: "Flag & Windsock Pole Lights",
    parentCategories: ["flagAndWindsockAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "flagAndWindsockPoleMountingHardwareAndKits",
    label: "Flag & Windsock Pole Mounting Hardware & Kits",
    parentCategories: ["flagAndWindsockAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "flagAndWindsockPoles",
    label: "Flag & Windsock Poles",
    parentCategories: ["flagAndWindsockAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "flagsAndWindsocks",
    label: "Flags & Windsocks",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "flamelessCandles",
    label: "Flameless Candles",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "fountainsAndPonds",
    label: "Fountains & Ponds",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "fountainAndPondAccessories",
    label: "Fountain & Pond Accessories",
    parentCategories: ["fountainsAndPonds", "decor", "homeAndLiving"]
  },
  {
    key: "fountainsAndWaterfalls",
    label: "Fountains & Waterfalls",
    parentCategories: ["fountainsAndPonds", "decor", "homeAndLiving"]
  },
  {
    key: "ponds",
    label: "Ponds",
    parentCategories: ["fountainsAndPonds", "decor", "homeAndLiving"]
  },
  {
    key: "gardenAndSteppingStones",
    label: "Garden & Stepping Stones",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "growthCharts",
    label: "Growth Charts",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "homeDecorDecals",
    label: "Home Decor Decals",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "homeFragranceAccessories",
    label: "Home Fragrance Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "candleAndOilWarmers",
    label: "Candle & Oil Warmers",
    parentCategories: ["homeFragranceAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "candleHolders",
    label: "Candle Holders",
    parentCategories: ["homeFragranceAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "candleSnuffers",
    label: "Candle Snuffers",
    parentCategories: ["homeFragranceAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "incenseHolders",
    label: "Incense Holders",
    parentCategories: ["homeFragranceAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "homeFragrances",
    label: "Home Fragrances Holders",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "airFresheners",
    label: "Air Fresheners",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "candles",
    label: "Candles",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "fragranceOil",
    label: "Fragrance Oil",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "incense",
    label: "Incense",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "potpourri",
    label: "Potpourri",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "waxTarts",
    label: "Wax Tarts",
    parentCategories: ["homeFragrances", "decor", "homeAndLiving"]
  },
  {
    key: "hourglasses",
    label: "Hourglasses",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "houseNumbersAndLetters",
    label: "House Numbers & Letters",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "lawnOrnamentsAndGardenSculptures",
    label: "Lawn Ornaments & Garden Sculptures",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "mailSlots",
    label: "Mail Slots",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "mailboxAccessories",
    label: "Mailbox Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "mailboxCovers",
    label: "Mailbox Covers",
    parentCategories: ["mailboxAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "mailboxEnclosures",
    label: "Mailbox Enclosures",
    parentCategories: ["mailboxAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "mailboxFlags",
    label: "Mailbox Flags",
    parentCategories: ["mailboxAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "mailboxPosts",
    label: "Mailbox Posts",
    parentCategories: ["mailboxAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "mailboxReplacementDoors",
    label: "Mailbox Replacement Doors",
    parentCategories: ["mailboxAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "mailboxes",
    label: "Mailboxes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "mirrors",
    label: "Mirrors",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "musicBoxes",
    label: "Music Boxes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "napkinRings",
    label: "Napkin Rings",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "noveltySigns",
    label: "Novelty Signs",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "ottomanCushions",
    label: "Ottoman Cushions",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "pictureFrames",
    label: "Picture Frames",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "piggyBanksAndMoneyJars",
    label: "Piggy Banks & Money Jars",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "rainChains",
    label: "Rain Chains",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "rainGauges",
    label: "Rain Gauges",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "refrigeratorMagnets",
    label: "Refrigerator Magnets",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "rugs",
    label: "Rugs",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "seasonalAndHolidayDecorations",
    label: "Seasonal & Holiday Decorations",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "adventCalendars",
    label: "Advent Calendars",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "christmasTreeSkirts",
    label: "Christmas Tree Skirts",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "christmasTreeStands",
    label: "Christmas Tree Stands",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "easterEggDecoratingKits",
    label: "Easter Egg Decorating Kits",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "holidayOrnamentDisplaysAndStands",
    label: "Holiday Ornament Displays & Stands",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "holidayOrnamentHooks",
    label: "Holiday Ornament Hooks",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "holidayOrnaments",
    label: "Holiday Ornaments",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "holidayStockingHangers",
    label: "Holiday Stocking Hangers",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "holidayStockings",
    label: "Holiday Stockings",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "japaneseTraditionalDolls",
    label: "Japanese Traditional Dolls",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "nativitySets",
    label: "Nativity Sets",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "seasonalVillageSetsAndAccessories",
    label: "Seasonal Village Sets & Accessories",
    parentCategories: ["seasonalAndHolidayDecorations", "decor", "homeAndLiving"]
  },
  {
    key: "shadowBoxes",
    label: "Shadow Boxes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "slipcovers",
    label: "Slipcovers",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "snowGlobes",
    label: "Snow Globes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "suncatchers",
    label: "Suncatchers",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "sundials",
    label: "Sundials",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "throwPillows",
    label: "Throw Pillows",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "trunks",
    label: "Trunks",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "vaseFillersAndTableScatters",
    label: "Vase Fillers & Table Scatters",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "vases",
    label: "Vases",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "wallpaper",
    label: "Wallpaper",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "weatherVanesAndRoofDecor",
    label: "Weather Vanes & Roof Decor",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "windChimes",
    label: "Wind Chimes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "windWheelsAndSpinners",
    label: "Wind Wheels & Spinners",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "windowMagnets",
    label: "Window Magnets",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "windowTreatmentAccessories",
    label: "Window Treatment Accessories",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "curtainAndDrapeRings",
    label: "Curtain & Drape Rings",
    parentCategories: ["windowTreatmentAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "curtainAndDrapeRods",
    label: "Curtain & Drape Rods",
    parentCategories: ["windowTreatmentAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "curtaiHoldbacksAndTassels",
    label: "Curtain Holdbacks & Tassels",
    parentCategories: ["windowTreatmentAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "windowTreatmentReplacementParts",
    label: "Window Treatment Replacement Parts",
    parentCategories: ["windowTreatmentAccessories", "decor", "homeAndLiving"]
  },
  {
    key: "windowTreatments",
    label: "Window Treatments",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "curtainsAndDrapes",
    label: "Curtains & Drapes",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "stainedGlassPanels",
    label: "Stained Glass Panels",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "windowBlindsAndShades",
    label: "Window Blinds & Shades",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "windowFilms",
    label: "Window Films",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "windowScreens",
    label: "Window Screens",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "windowValancesAndCornices",
    label: "Window Valances & Cornices",
    parentCategories: ["windowTreatments", "decor", "homeAndLiving"]
  },
  {
    key: "worldGlobes",
    label: "World Globes",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "wreathsAndGarlands",
    label: "Wreaths & Garlands",
    parentCategories: ["decor", "homeAndLiving"]
  },
  {
    key: "emergencyPreparedness",
    label: "Emergency Preparedness",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "earthquakeAlarms",
    label: "Earthquake Alarms",
    parentCategories: ["emergencyPreparedness", "homeAndLiving"]
  },
  {
    key: "emergencyBlankets",
    label: "Emergency Blankets",
    parentCategories: ["emergencyPreparedness", "homeAndLiving"]
  },
  {
    key: "emergencyFoodKits",
    label: "Emergency Food Kits",
    parentCategories: ["emergencyPreparedness", "homeAndLiving"]
  },
  {
    key: "emergencyToolsAndKits",
    label: "Emergency Tools & Kits",
    parentCategories: ["emergencyPreparedness", "homeAndLiving"]
  },
  {
    key: "furnitureAnchors",
    label: "Furniture Anchors",
    parentCategories: ["emergencyPreparedness", "homeAndLiving"]
  },
  {
    key: "fireplaceAndWoodStoveAccessories",
    label: "Fireplace & Wood Stove Accessories",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "bellows",
    label: "Bellows",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "fireplaceAndWoodStoveGrates",
    label: "Fireplace & Wood Stove Grates",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "fireplaceAndirons",
    label: "Fireplace Andirons",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "fireplaceReflectors",
    label: "Fireplace Reflectors",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "fireplaceScreens",
    label: "Fireplace Screens",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "fireplaceTools",
    label: "Fireplace Tools",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "firewoodAndFuel",
    label: "Firewood & Fuel",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "hearthPads",
    label: "Hearth Pads",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "logRackAndCarrierAccessories",
    label: "Log Rack & Carrier Accessories",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "logRacksAndCarriers",
    label: "Log Racks & Carriers",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "woodStoveFansAndBlowers",
    label: "Wood Stove Fans & Blowers",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "Fireplaces",
    label: "Fireplaces",
    parentCategories: ["fireplaceAndWoodStoveAccessories", "homeAndLiving"]
  },
  {
    key: "floodFireAndGasSafety",
    label: "Flood, Fire & Gas Safety",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "fireAlarmControlPanels",
    label: "Fire Alarm Control Panels",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "fireAlarms",
    label: "Fire Alarms",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "fireExtinguisherAndEquipmentStorage",
    label: "Fire Extinguisher & Equipment Storage",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "fireExtinguishers",
    label: "Fire Extinguishers",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "fireSprinklers",
    label: "Fire Sprinklers",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "heatDetectors",
    label: "Heat Detectors",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "smokeAndCarbonMonoxideDetectors",
    label: "Smoke & Carbon Monoxide Detectors",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "carbonMonoxideDetectors",
    label: "Carbon Monoxide Detectors",
    parentCategories: ["smokeAndCarbonMonoxideDetectors", "floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "smokeDetectors",
    label: "Smoke Detectors",
    parentCategories: ["smokeAndCarbonMonoxideDetectors", "floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "waterAndFloodDetectors",
    label: "Water & Flood Detectors",
    parentCategories: ["floodFireAndGasSafety", "homeAndLiving"]
  },
  {
    key: "partyAndCelebration",
    label: "Party & Celebration",
    parentCategories: ["homeAndLiving"]
  },
  {
    key: "giftGiving",
    label: "Gift Giving",
    parentCategories: ["partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "corsageAndBoutonnièrePins",
    label: "Corsage & Boutonnière Pins",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "corsagesAndBoutonnières",
    label: "Corsages & Boutonnières",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "freshCutFlowers",
    label: "Fresh Cut Flowers",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "giftCardsAndCertificates",
    label: "Gift Cards & Certificates",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "giftWrapping",
    label: "Gift Wrapping",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "giftBags",
    label: "Gift Bags",
    parentCategories: ["giftWrapping", "giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "giftBoxesAndTins",
    label: "Gift Boxes & Tins",
    parentCategories: ["giftWrapping", "giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "giftTagsAndLabels",
    label: "Gift Tags & Labels",
    parentCategories: ["giftWrapping", "giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "tissuePaper",
    label: "Tissue Paper",
    parentCategories: ["giftWrapping", "giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "wrappingPaper",
    label: "Wrapping Paper",
    parentCategories: ["giftWrapping", "giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "greetingAndNoteCards",
    label: "Greeting & Note Cards",
    parentCategories: ["giftGiving", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partySupplies",
    label: "Party Supplies",
    parentCategories: ["partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "adviceCards",
    label: "Advice Cards",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "balloonKits",
    label: "Balloon Kits",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "balloons",
    label: "Balloons",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "banners",
    label: "Banners",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "birthdayCandles",
    label: "Birthday Candles",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "chairSashes",
    label: "Chair Sashes",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "cocktailDecorations",
    label: "Cocktail Decorations",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "confetti",
    label: "Confetti",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "decorativePomPoms",
    label: "Decorative Pom-Poms",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "drinkingGames",
    label: "Drinking Games",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "beerPong",
    label: "Beer Pong",
    parentCategories: ["drinkingGames", "partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "Beer Pong Tables",
    label: "Beer Pong Tables",
    parentCategories: ["beerPong", "drinkingGames", "partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "drinkingStrawsAndStirrers",
    label: "Drinking Straws & Stirrers",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "envelopeSeals",
    label: "Envelope Seals",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "eventPrograms",
    label: "Event Programs",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "fireworksAndFirecrackers",
    label: "Fireworks & Firecrackers",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "inflatablePartyDecorations",
    label: "Inflatable Party Decorations",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "invitations",
    label: "Invitations",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "noisemakersAndPartyBlowers",
    label: "Noisemakers & Party Blowers",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partyFavors",
    label: "Party Favors",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "weddingFavors",
    label: "Wedding Favors",
    parentCategories: ["partyFavors", "partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partyGames",
    label: "Party Games",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partyHats",
    label: "Party Hats",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partyStreamersAndCurtains",
    label: "Party Streamers & Curtains",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "partySupplyKits",
    label: "Party Supply Kits",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "pinatas",
    label: "Piñatas",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "placeCardHolders",
    label: "Place Card Holders",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "placeCards",
    label: "Place Cards",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "responseCards",
    label: "Response Cards",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "sparklers",
    label: "Sparklers",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "specialOccasionCardBoxesAndHolders",
    label: "Special Occasion Card Boxes & Holders",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "sprayString",
    label: "Spray String",
    parentCategories: ["partySupplies", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "specialEffects",
    label: "Special Effects",
    parentCategories: ["partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "discoBalls",
    label: "Disco Balls",
    parentCategories: ["specialEffects", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "fogMachines",
    label: "Fog Machines",
    parentCategories: ["specialEffects", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "specialEffectsControllers",
    label: "Special Effects Controllers",
    parentCategories: ["specialEffects", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "specialEffectsLightStands",
    label: "Special Effects Light Stands",
    parentCategories: ["specialEffects", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "specialEffectsLighting",
    label: "Special Effects Lighting",
    parentCategories: ["specialEffects", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "trophiesAndAwards",
    label: "Trophies & Awards",
    parentCategories: ["partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "awardCertificates",
    label: "Award Certificates",
    parentCategories: ["trophiesAndAwards", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "awardPinsAndMedals",
    label: "Award Pins & Medals",
    parentCategories: ["trophiesAndAwards", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "awardPlaques",
    label: "Award Plaques",
    parentCategories: ["trophiesAndAwards", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "awardRibbons",
    label: "Award Ribbons",
    parentCategories: ["trophiesAndAwards", "partyAndCelebration", "homeAndLiving"]
  },
  {
    key: "trophies",
    label: "Trophies",
    parentCategories: ["trophiesAndAwards", "partyAndCelebration", "homeAndLiving"]
  },
  // ===================================================================
  // Beauty & Personal Care
  // ===================================================================
  {
    key: "beautyAndPersonalCare",
    label: "Beauty & Personal Care",
  },
  {
    key: "personalCare",
    label: "Personal Care",
    parentCategories: ["beautyAndPersonalCare"]
  },
  {
    key: "cosmetics",
    label: "Cosmetics",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathAndBody",
    label: "Bath & Body",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "adultHygienicWipes",
    label: "Adult Hygienic Wipes",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "barSoap",
    label: "Bar Soap",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathAdditives",
    label: "Bath Additives",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathBrushes",
    label: "Bath Brushes",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathSpongesAndLoofahs",
    label: "Bath Sponges & Loofahs",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyWash",
    label: "Body Wash",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "handSanitizersAndWipes",
    label: "Hand Sanitizers & Wipes",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "liquidHandSoap",
    label: "Liquid Hand Soap",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "powderedHandSoap",
    label: "Powdered Hand Soap",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "showerCaps",
    label: "Shower Caps",
    parentCategories: ["bathAndBody", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathAndBodyGiftSets",
    label: "Bath & Body Gift Sets",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bathAndBodyGiftSets",
    label: "Bath & Body Gift Sets",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cosmeticSets",
    label: "Cosmetic Sets",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cosmeticToolCleansers",
    label: "Cosmetic Tool Cleansers",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cosmeticTool",
    label: "Cosmetic Tool",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeupTools",
    label: "Makeup Tools",
    parentCategories: ["cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "doubleEyelidGlueAndTape",
    label: "Double Eyelid Glue & Tape",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyebrowStencils",
    label: "Eyebrow Stencils",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyelashCurlerRefills",
    label: "Eyelash Curler Refills",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyelashCurlers",
    label: "Eyelash Curlers",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "faceMirrors",
    label: "Face Mirrors",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facialBlottingPaper",
    label: "Facial Blotting Paper",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseEyelashAccessories",
    label: "False Eyelash Accessories",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseEyelashAdhesive",
    label: "False Eyelash Adhesive",
    parentCategories: ["falseEyelashAccessories", "makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseEyelashApplicators",
    label: "False Eyelash Applicators",
    parentCategories: ["falseEyelashAccessories", "makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseEyelashRemover",
    label: "False Eyelash Remover",
    parentCategories: ["falseEyelashAccessories", "makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeupBrushes",
    label: "Makeup Brushes",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeupSponges",
    label: "Makeup Sponges",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "refillableMakeupPalettesAndCases",
    label: "Refillable Makeup Palettes & Cases",
    parentCategories: ["makeupTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailTools",
    label: "Nail Tools",
    parentCategories: ["cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cuticlePushers",
    label: "Cuticle Pushers",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cuticleScissors",
    label: "Cuticle Scissors",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "manicureAndPedicureSpacers",
    label: "Manicure & Pedicure Spacers",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "manicureToolSets",
    label: "Manicure Tool Sets",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailBuffers",
    label: "Nail Buffers",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailClippers",
    label: "Nail Clippers",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailDrillAccessories",
    label: "Nail Drill Accessories",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailDrills",
    label: "Nail Drills",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailDryers",
    label: "Nail Dryers",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailFilesAndEmeryBoards",
    label: "Nail Files & Emery Boards",
    parentCategories: ["nailTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCareTools",
    label: "Skin Care Tools",
    parentCategories: ["cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facialSaunas",
    label: "Facial Saunas",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "footFiles",
    label: "Foot Files",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lotionAndSunscreenApplicators",
    label: "Lotion & Sunscreen Applicators",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "pumiceStones",
    label: "Pumice Stones",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCareExtractors",
    label: "Skin Care Extractors",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCareRollers",
    label: "Skin Care Rollers",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCleansingBrushHeads",
    label: "Skin Cleansing Brush Heads",
    parentCategories: ["skinCareTools", "cosmeticTool", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeup",
    label: "Makeup",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyMakeup",
    label: "Body Makeup",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyAndHairGlitter",
    label: "Body & Hair Glitter",
    parentCategories: ["bodyMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyPaintAndFoundation",
    label: "Body Paint & Foundation",
    parentCategories: ["bodyMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "costumeAndStageMakeup",
    label: "Costume & Stage Makeup",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyeMakeup",
    label: "Eye Makeup",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyePrimer",
    label: "Eye Primer",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyeShadow",
    label: "Eye Shadow",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyebrowEnhancers",
    label: "Eyebrow Enhancers",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyeliner",
    label: "Eyeliner",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseEyelashes",
    label: "False Eyelashes",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lashAndBrowGrowthTreatments",
    label: "Lash & Brow Growth Treatments",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "mascara",
    label: "Mascara",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "mascaraPrimer",
    label: "Mascara Primer",
    parentCategories: ["eyeMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "faceMakeup",
    label: "Face Makeup",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "blushesAndBronzers",
    label: "Blushes & Bronzers",
    parentCategories: ["faceMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facePowder",
    label: "Face Powder",
    parentCategories: ["faceMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facePrimer",
    label: "Face Primer",
    parentCategories: ["faceMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "foundationsAndConcealers",
    label: "Foundations & Concealers",
    parentCategories: ["faceMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "highlightersAndLuminizers",
    label: "Highlighters & Luminizers",
    parentCategories: ["faceMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipMakeup",
    label: "Lip Makeup",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipAndCheekStains",
    label: "Lip & Cheek Stains",
    parentCategories: ["lipMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipGloss",
    label: "Lip Gloss",
    parentCategories: ["lipMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipLiner",
    label: "Lip Liner",
    parentCategories: ["lipMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipPrimer",
    label: "Lip Primer",
    parentCategories: ["lipMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipstick",
    label: "Lipstick",
    parentCategories: ["lipMakeup", "makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeupFinishingSprays",
    label: "Makeup Finishing Sprays",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "temporaryTattoos",
    label: "Temporary Tattoos",
    parentCategories: ["makeup", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailCare",
    label: "Nail Care",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cuticleCreamAndOil",
    label: "Cuticle Cream & Oil",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "falseNails",
    label: "False Nails",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "manicureGlue",
    label: "Manicure Glue",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailArtKitsAndAccessories",
    label: "Nail Art Kits & Accessories",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailPolishDryingDropsAndSprays",
    label: "Nail Polish Drying Drops & Sprays",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailPolishRemovers",
    label: "Nail Polish Removers",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailPolishThinners",
    label: "Nail Polish Thinners",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "nailPolishes",
    label: "Nail Polishes",
    parentCategories: ["nailCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "perfumeAndCologne",
    label: "Perfume & Cologne",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCare",
    label: "Skin Care",
    parentCategories: ["cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "acneTreatmentsAndKits",
    label: "Acne Treatments & Kits",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "antiAgingSkinCareKits",
    label: "Anti-Aging Skin Care Kits",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyOil",
    label: "Body Oil",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "bodyPowder",
    label: "Body Powder",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "compressedSkinCareMaskSheets",
    label: "Compressed Skin Care Mask Sheets",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facialCleansers",
    label: "Facial Cleansers",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facialCleansingKits",
    label: "Facial Cleansing Kits",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "facialPoreStrips",
    label: "Facial Pore Strips",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipBalmsAndTreatments",
    label: "Lip Balms & Treatments",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lipBalms",
    label: "Lip Balms",
    parentCategories: ["lipBalmsAndTreatments", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "medicatedLipTreatments",
    label: "Medicated Lip Treatments",
    parentCategories: ["lipBalmsAndTreatments", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "lotionAndMoisturizer",
    label: "Lotion & Moisturizer",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "makeupRemovers",
    label: "Makeup Removers",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "petroleumJelly",
    label: "Petroleum Jelly",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinCareMasksAndPeels",
    label: "Skin Care Masks & Peels",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "skinInsectRepellent",
    label: "Skin Insect Repellent",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "sunscreen",
    label: "Sunscreen",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "tanningProducts",
    label: "Tanning Products",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "selfTanner",
    label: "Self Tanner",
    parentCategories: ["tanningProducts", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "tanningOilAndLotion",
    label: "Tanning Oil & Lotion",
    parentCategories: ["tanningProducts", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "tonersAndAstringents",
    label: "Toners & Astringents",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "astringents",
    label: "Astringents",
    parentCategories: ["tonersAndAstringents", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "toners",
    label: "Toners",
    parentCategories: ["tonersAndAstringents", "skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "wartRemovers",
    label: "Wart Removers",
    parentCategories: ["skinCare", "cosmetics", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cottonBalls",
    label: "Cotton Balls",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "cottonSwabs",
    label: "Cotton Swabs",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "deodorantAndAntiPerspirant",
    label: "Deodorant & Anti-Perspirant",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "antiPerspirant",
    label: "Anti-Perspirant",
    parentCategories: ["deodorantAndAntiPerspirant", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "deodorant",
    label: "Deodorant",
    parentCategories: ["deodorantAndAntiPerspirant", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairCare",
    label: "Hair Care",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairCareKits",
    label: "Hair Care Kits",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairColor",
    label: "Hair Color",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairColorRemovers",
    label: "Hair Color Removers",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairColoringAccessories",
    label: "Hair Coloring Accessories",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairLossConcealers",
    label: "Hair Loss Concealers",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairLossTreatments",
    label: "Hair Loss Treatments",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairPermanentsAndStraighteners",
    label: "Hair Permanents & Straighteners",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairShears",
    label: "Hair Shears",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairSteamersAndHeatCaps",
    label: "Hair Steamers & Heat Caps",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairStylingProducts",
    label: "Hair Styling Products",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairStylingToolAccessories",
    label: "Hair Styling Tool Accessories",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairCurlerClipsAndPins",
    label: "Hair Curler Clips & Pins",
    parentCategories: ["hairStylingToolAccessories", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairDryerAccessories",
    label: "Hair Dryer Accessories",
    parentCategories: ["hairStylingToolAccessories", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairIronAccessories",
    label: "Hair Iron Accessories",
    parentCategories: ["hairStylingToolAccessories", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairStylingTools",
    label: "Hair Styling Tools",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "combsAndBrushes",
    label: "Combs & Brushes",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "curlingIrons",
    label: "Curling Irons",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairCurlers",
    label: "Hair Curlers",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairDryers",
    label: "Hair Dryers",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairStraighteners",
    label: "Hair Straighteners",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "hairStylingToolSets",
    label: "Hair Styling Tool Sets",
    parentCategories: ["hairStylingTools", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "shampooAndConditioner",
    label: "Shampoo & Conditioner",
    parentCategories: ["hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "conditioners",
    label: "Conditioners",
    parentCategories: ["shampooAndConditioner", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "shampoo",
    label: "Shampoo",
    parentCategories: ["shampooAndConditioner", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "shampooAndConditionerSets",
    label: "Shampoo & Conditioner Sets",
    parentCategories: ["shampooAndConditioner", "hairCare", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageAndRelaxation",
    label: "Massage & Relaxation",
    parentCategories: ["personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "backScratchers",
    label: "Back Scratchers",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "eyePillows",
    label: "Eye Pillows",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageChairs",
    label: "Massage Chairs",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageOil",
    label: "Massage Oil",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageStoneWarmers",
    label: "Massage Stone Warmers",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageStones",
    label: "Massage Stones",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageTables",
    label: "Massage Tables",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageTables",
    label: "Massage Tables",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massagers",
    label: "Massagers",
    parentCategories: ["massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "electricMassagers",
    label: "Electric Massagers",
    parentCategories: ["massagers", "massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "manualMassageTools",
    label: "Manual Massage Tools",
    parentCategories: ["massagers", "massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  {
    key: "massageCushions",
    label: "Massage Cushions",
    parentCategories: ["massagers", "massageAndRelaxation", "personalCare", "beautyAndPersonalCare"]
  },
  // ===================================================================
  // Fashion
  // ===================================================================
  {
    key: "fashion",
    label: "Fashion",
  },
  {
    key: "apparelAndAccessories",
    label: "Apparel & Accessories",
    parentCategories: ["fashion"]
  },
  {
    key: "clothing",
    label: "Clothing",
    parentCategories: ["fashion"]
  },
  {
    key: "activewear",
    label: "Activewear",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "americanFootballPants",
    label: "American Football Pants",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleActivewear",
    label: "Bicycle Activewear",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleBibs",
    label: "Bicycle Bibs",
    parentCategories: ["bicycleActivewear", "activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleJerseys",
    label: "Bicycle Jerseys",
    parentCategories: ["bicycleActivewear", "activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleShortsAndBriefs",
    label: "Bicycle Shorts & Briefs",
    parentCategories: ["bicycleActivewear", "activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleSkinsuits",
    label: "Bicycle Skinsuits",
    parentCategories: ["bicycleActivewear", "activewear", "clothing", "fashion"]
  },
  {
    key: "bicycleTights",
    label: "Bicycle Tights",
    parentCategories: ["bicycleActivewear", "activewear", "clothing", "fashion"]
  },
  {
    key: "boxingShorts",
    label: "Boxing Shorts",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "danceDressesSkirtsAndCostumes",
    label: "Dance Dresses, Skirts & Costumes",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "huntingClothing",
    label: "Hunting Clothing",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "ghillieSuits",
    label: "Ghillie Suits",
    parentCategories: ["huntingClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "huntingAndFishingVests",
    label: "Hunting & Fishing Vests",
    parentCategories: ["huntingClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "huntingAndTacticalPants",
    label: "Hunting & Tactical Pants",
    parentCategories: ["huntingClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "martialArtsShorts",
    label: "Martial Arts Shorts",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "motorcycleProtectiveClothing",
    label: "Motorcycle Protective Clothing",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "motorcycleJackets",
    label: "Motorcycle Jackets",
    parentCategories: ["motorcycleProtectiveClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "motorcyclePants",
    label: "Motorcycle Pants",
    parentCategories: ["motorcycleProtectiveClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "motorcycleSuits",
    label: "Motorcycle Suits",
    parentCategories: ["motorcycleProtectiveClothing", "activewear", "clothing", "fashion"]
  },
  {
    key: "paintballClothing",
    label: "Paintball Clothing",
    parentCategories: ["activewear", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerClothing",
    label: "Baby & Toddler Clothing",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "babyAndToddlerBottoms",
    label: "Baby & Toddler Bottoms",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerDiaperCovers",
    label: "Baby & Toddler Diaper Covers",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerDresses",
    label: "Baby & Toddler Dresses",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerOuterwear",
    label: "Baby & Toddler Outerwear",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerOutfits",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerSleepwear",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerSocksAndTights",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerSwimwear",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyAndToddlerTops",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "babyOnePieces",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "toddlerUnderwear",
    label: "Baby & Toddler Outfits",
    parentCategories: ["babyAndToddlerClothing", "clothing", "fashion"]
  },
  {
    key: "dresses",
    label: "Dresses",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "onePieces",
    label: "One-Pieces",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "jumpsuitsAndRompers",
    label: "Jumpsuits & Rompers",
    parentCategories: ["onePieces", "clothing", "fashion"]
  },
  {
    key: "leotardsAndUnitards",
    label: "Leotards & Unitards",
    parentCategories: ["onePieces", "clothing", "fashion"]
  },
  {
    key: "overalls",
    label: "Overalls",
    parentCategories: ["onePieces", "clothing", "fashion"]
  },
  {
    key: "outerwear",
    label: "Outerwear",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "chaps",
    label: "Chaps",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "coatsAndJackets",
    label: "Coats & Jackets",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "rainPants",
    label: "Rain Pants",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "rainSuits",
    label: "Rain Suits",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "snowPantsAndSuits",
    label: "Snow Pants & Suits",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "vests",
    label: "Vests",
    parentCategories: ["outerwear", "clothing", "fashion"]
  },
  {
    key: "outfitSets",
    label: "Outfit Sets",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "pants",
    label: "Pants",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "shirtsAndTops",
    label: "Shirts & Tops",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "shorts",
    label: "Shorts",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "skirts",
    label: "Skirts",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "kneeLengthSkirts",
    label: "Knee-Length Skirts",
    parentCategories: ["skirts", "clothing", "fashion"]
  },
  {
    key: "longSkirts",
    label: "Long Skirts",
    parentCategories: ["skirts", "clothing", "fashion"]
  },
  {
    key: "miniSkirts",
    label: "Mini Skirts",
    parentCategories: ["skirts", "clothing", "fashion"]
  },
  {
    key: "skorts",
    label: "Skorts",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "sleepwearAndLoungewear",
    label: "Sleepwear & Loungewear",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "loungewear",
    label: "Loungewear",
    parentCategories: ["sleepwearAndLoungewear", "clothing", "fashion"]
  },
  {
    key: "nightgowns",
    label: "Nightgowns",
    parentCategories: ["sleepwearAndLoungewear", "clothing", "fashion"]
  },
  {
    key: "pajamas",
    label: "Pajamas",
    parentCategories: ["sleepwearAndLoungewear", "clothing", "fashion"]
  },
  {
    key: "robes",
    label: "Robes",
    parentCategories: ["sleepwearAndLoungewear", "clothing", "fashion"]
  },
  {
    key: "suits",
    label: "Suits",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "pantSuits",
    label: "Pant Suits",
    parentCategories: ["suits", "clothing", "fashion"]
  },
  {
    key: "skirtSuits",
    label: "Skirt Suits",
    parentCategories: ["suits", "clothing", "fashion"]
  },
  {
    key: "tuxedos",
    label: "Tuxedos",
    parentCategories: ["suits", "clothing", "fashion"]
  },
  {
    key: "swimwear",
    label: "Swimwear",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "traditionalAndCeremonialClothing",
    label: "Traditional & Ceremonial Clothing",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "dirndls",
    label: "Dirndls",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "hakamaTrousers",
    label: "Hakama Trousers",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "japaneseBlackFormalWear",
    label: "Japanese Black Formal Wear",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "kimonoOuterwear",
    label: "Kimono Outerwear",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "haoriJackets",
    label: "Haori Jackets",
    parentCategories: ["kimonoOuterwear", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "kimonoCoats",
    label: "Kimono Coats",
    parentCategories: ["kimonoOuterwear", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "kimonos",
    label: "Kimonos",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "bridalKimonos",
    label: "Bridal Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "casualKimonos",
    label: "Casual Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "furisodeKimonos",
    label: "Furisode Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "iromujiKimonos",
    label: "Iromuji Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "komonKimonos",
    label: "Komon Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "tomesodeAndHoumongiKimonos",
    label: "Tomesode & Houmongi Kimonos",
    parentCategories: ["kimonos", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "religiousCeremonialClothing",
    label: "Religious Ceremonial Clothing",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "baptismAndCommunionDresses",
    label: "Baptism & Communion Dresses",
    parentCategories: ["religiousCeremonialClothing", "traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "sarisAndLehengas",
    label: "Saris & Lehengas",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "traditionalLeatherPants",
    label: "Traditional Leather Pants",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "yukata",
    label: "Yukata",
    parentCategories: ["traditionalAndCeremonialClothing", "clothing", "fashion"]
  },
  {
    key: "underwearAndSocks",
    label: "Underwear & Socks",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "braAccessories",
    label: "Bra Accessories",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "braStrapPads",
    label: "Bra Strap Pads",
    parentCategories: ["braAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "braStrapsAndExtenders",
    label: "Bra Straps & Extenders",
    parentCategories: ["braAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "breastEnhancingInserts",
    label: "Breast Enhancing Inserts",
    parentCategories: ["braAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "breastPetalsAndConcealers",
    label: "Breast Petals & Concealers",
    parentCategories: ["braAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "bras",
    label: "Bras",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "hosiery",
    label: "Hosiery",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "jockStraps",
    label: "Jock Straps",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "lingerie",
    label: "Lingerie",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "lingerieAccessories",
    label: "Lingerie Accessories",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "Garter Belts",
    label: "Garter Belts",
    parentCategories: ["lingerieAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "Garters",
    label: "Garters",
    parentCategories: ["lingerieAccessories", "underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "longJohns",
    label: "Long Johns",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "petticoatsAndPettipants",
    label: "Petticoats & Pettipants",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "shapewear",
    label: "Shapewear",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "socks",
    label: "Socks",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "undershirts",
    label: "Undershirts",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "underwear",
    label: "Underwear",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "underwearSlips",
    label: "Underwear Slips",
    parentCategories: ["underwearAndSocks", "clothing", "fashion"]
  },
  {
    key: "uniforms",
    label: "Uniforms",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "contractorPantsAndCoveralls",
    label: "Contractor Pants & Coveralls",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "flightSuits",
    label: "Flight Suits",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "foodServiceUniforms",
    label: "Food Service Uniforms",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "chefsHats",
    label: "Chef's Hats",
    parentCategories: ["foodServiceUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "chefsJackets",
    label: "Chef's Jackets",
    parentCategories: ["foodServiceUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "chefsPants",
    label: "Chef's Pants",
    parentCategories: ["foodServiceUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "militaryUniforms",
    label: "Military Uniforms",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "schoolUniforms",
    label: "School Uniforms",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "securityUniforms",
    label: "Security Uniforms",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "sportsUniforms",
    label: "Sports Uniforms",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "americanFootballUniforms",
    label: "American Football Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "baseballUniforms",
    label: "Baseball Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "basketballUniforms",
    label: "Basketball Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "cheerleadingUniforms",
    label: "Cheerleading Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "cricketUniforms",
    label: "Cricket Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "hockeyUniforms",
    label: "Hockey Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "martialArtsUniforms",
    label: "Martial Arts Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "officiatingUniforms",
    label: "Officiating Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "soccerUniforms",
    label: "Soccer Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "softballUniforms",
    label: "Softball Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "wrestlingUniforms",
    label: "Wrestling Uniforms",
    parentCategories: ["sportsUniforms", "uniforms", "clothing", "fashion"]
  },
  {
    key: "whiteCoats",
    label: "White Coats",
    parentCategories: ["uniforms", "clothing", "fashion"]
  },
  {
    key: "weddingAndBridalPartyDresses",
    label: "Wedding & Bridal Party Dresses",
    parentCategories: ["clothing", "fashion"]
  },
  {
    key: "bridalPartyDresses",
    label: "Bridal Party Dresses",
    parentCategories: ["weddingAndBridalPartyDresses", "clothing", "fashion"]
  },
  {
    key: "weddingDresses",
    label: "Wedding Dresses",
    parentCategories: ["weddingAndBridalPartyDresses", "clothing", "fashion"]
  },
  {
    key: "clothingAccessories",
    label: "Clothing Accessories",
    parentCategories: ["fashion"]
  },
  {
    key: "armWarmersAndSleeves",
    label: "Arm Warmers & Sleeves",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "babyAndToddlerClothingAccessories",
    label: "Baby & Toddler Clothing Accessories",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "babyAndToddlerBelts",
    label: "Baby & Toddler Belts",
    parentCategories: ["babyAndToddlerClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "babyAndToddlerGlovesAndMittens",
    label: "Baby & Toddler Gloves & Mittens",
    parentCategories: ["babyAndToddlerClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "babyAndToddlerHats",
    label: "Baby & Toddler Hats",
    parentCategories: ["babyAndToddlerClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "babyProtectiveWear",
    label: "Baby Protective Wear",
    parentCategories: ["babyAndToddlerClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "balaclavas",
    label: "Balaclavas",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "bandanasAndHeadties",
    label: "Bandanas & Headties",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "bandanas",
    label: "Bandanas",
    parentCategories: ["bandanasAndHeadties", "clothingAccessories", "fashion"]
  },
  {
    key: "hairCareWraps",
    label: "Hair Care Wraps",
    parentCategories: ["bandanasAndHeadties", "clothingAccessories", "fashion"]
  },
  {
    key: "beltBuckles",
    label: "Belt Buckles",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "belts",
    label: "Belts",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "bridalAccessories",
    label: "Bridal Accessories",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "bridalVeils",
    label: "Bridal Veils",
    parentCategories: ["bridalAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "buttonStuds",
    label: "Button Studs",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "collarStays",
    label: "Collar Stays",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "cufflinks",
    label: "Cufflinks",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "decorativeFans",
    label: "Decorative Fans",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "earmuffs",
    label: "Earmuffs",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "glovesAndMittens",
    label: "Gloves & Mittens",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "hairAccessories",
    label: "Hair Accessories",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "hairBunAndVolumeShapers",
    label: "Hair Bun & Volume Shapers",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairCombs",
    label: "Hair Combs",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairExtensions",
    label: "Hair Extensions",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairForksAndSticks",
    label: "Hair Forks & Sticks",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairNets",
    label: "Hair Nets",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairPinsClawsAndClips",
    label: "Hair Pins, Claws & Clips",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "barrettes",
    label: "Barrettes",
    parentCategories: ["hairPinsClawsAndClips", "hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairClawsAndClips",
    label: "Hair Claws & Clips",
    parentCategories: ["hairPinsClawsAndClips", "hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairPins",
    label: "Hair Pins",
    parentCategories: ["hairPinsClawsAndClips", "hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "hairWreaths",
    label: "Hair Wreaths",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "headbands",
    label: "Headbands",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "ponytailHolders",
    label: "Ponytail Holders",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "tiaras",
    label: "Tiaras",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "wigAccessories",
    label: "Wig Accessories",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "wigCaps",
    label: "Wig Caps",
    parentCategories: ["wigAccessories", "hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "wigGlueAndTape",
    label: "Wig Glue & Tape",
    parentCategories: ["wigAccessories", "hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "wigs",
    label: "Wigs",
    parentCategories: ["hairAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "handMuffs",
    label: "Hand Muffs",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "handkerchiefs",
    label: "Handkerchiefs",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "hats",
    label: "Hats",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "headwear",
    label: "Headwear",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "fascinators",
    label: "Fascinators",
    parentCategories: ["headwear", "clothingAccessories", "fashion"]
  },
  {
    key: "headdresses",
    label: "Headdresses",
    parentCategories: ["headwear", "clothingAccessories", "fashion"]
  },
  {
    key: "turbans",
    label: "Turbans",
    parentCategories: ["headwear", "clothingAccessories", "fashion"]
  },
  {
    key: "legWarmers",
    label: "Leg Warmers",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "leis",
    label: "Leis",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "maternityBeltsAndSupportBands",
    label: "Maternity Belts & Support Bands",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "neckGaiters",
    label: "Neck Gaiters",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "neckties",
    label: "Neckties",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "pinbackButtons",
    label: "Pinback Buttons",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "sashes",
    label: "Sashes",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "scarvesAndShawls",
    label: "Scarves & Shawls",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "scarves",
    label: "Scarves",
    parentCategories: ["scarvesAndShawls", "clothingAccessories", "fashion"]
  },
  {
    key: "shawls",
    label: "Shawls",
    parentCategories: ["scarvesAndShawls", "clothingAccessories", "fashion"]
  },
  {
    key: "sunglasses",
    label: "Sunglasses",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "suspenders",
    label: "Suspenders",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "tieClips",
    label: "Tie Clips",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "traditionalClothingAccessories",
    label: "Traditional Clothing Accessories",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "kimonoUnderclothes",
    label: "Kimono Underclothes",
    parentCategories: ["traditionalClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "obiAccessories",
    label: "Obi Accessories",
    parentCategories: ["traditionalClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "obis",
    label: "Obis",
    parentCategories: ["traditionalClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "tabiSocks",
    label: "Tabi Socks",
    parentCategories: ["traditionalClothingAccessories", "clothingAccessories", "fashion"]
  },
  {
    key: "wristbands",
    label: "Wristbands",
    parentCategories: ["clothingAccessories", "fashion"]
  },
  {
    key: "costumesAndAccessories",
    label: "Costumes & Accessories",
    parentCategories: ["fashion"]
  },
  {
    key: "costumeAccessories",
    label: "Costume Accessories",
    parentCategories: ["costumesAndAccessories", "fashion"]
  },
  {
    key: "baldCaps",
    label: "Bald Caps",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeAccessorySets",
    label: "Costume Accessory Sets",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeCapes",
    label: "Costume Capes",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeGloves",
    label: "Costume Gloves",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeHats",
    label: "Costume Hats",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeSpecialEffects",
    label: "Costume Special Effects",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeTobaccoProducts",
    label: "Costume Tobacco Products",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "pretendJewelry",
    label: "Pretend Jewelry",
    parentCategories: ["costumeAccessories", "costumesAndAccessories", "fashion"]
  },
  {
    key: "costumeShoes",
    label: "Costume Shoes",
    parentCategories: ["costumesAndAccessories", "fashion"]
  },
  {
    key: "costumes",
    label: "Costumes",
    parentCategories: ["costumesAndAccessories", "fashion"]
  },
  {
    key: "masks",
    label: "Masks",
    parentCategories: ["costumesAndAccessories", "fashion"]
  },
  {
    key: "handbagAndWalletAccessories",
    label: "Handbag & Wallet Accessories",
    parentCategories: ["fashion"]
  },
  {
    key: "checkbookCovers",
    label: "Checkbook Covers",
    parentCategories: ["handbagAndWalletAccessories", "fashion"]
  },
  {
    key: "keychains",
    label: "Keychains",
    parentCategories: ["handbagAndWalletAccessories", "fashion"]
  },
  {
    key: "lanyards",
    label: "Lanyards",
    parentCategories: ["handbagAndWalletAccessories", "fashion"]
  },
  {
    key: "walletChains",
    label: "Wallet Chains",
    parentCategories: ["handbagAndWalletAccessories", "fashion"]
  },
  {
    key: "handbagsWalletsAndCases",
    label: "Handbags, Wallets & Cases",
    parentCategories: ["fashion"]
  },
  {
    key: "badgeAndPassHolders",
    label: "Badge & Pass Holders",
    parentCategories: ["handbagsWalletsAndCases", "fashion"]
  },
  {
    key: "businessCardCases",
    label: "Business Card Cases",
    parentCategories: ["handbagsWalletsAndCases", "fashion"]
  },
  {
    key: "handbags",
    label: "Handbags",
    parentCategories: ["handbagsWalletsAndCases", "fashion"]
  },
  {
    key: "walletsAndMoneyClips",
    label: "Wallets & Money Clips",
    parentCategories: ["handbagsWalletsAndCases", "fashion"]
  }
];













