import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFavorites.module.css';

const IconFavorites = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === "big") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#201F22"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.25"
          d="M15.733 10.741l-5.727 5.509-5.726-5.509m0 0a3.705 3.705 0 01-.874-1.26 3.615 3.615 0 01.081-2.974 3.72 3.72 0 01.94-1.214c.397-.337.862-.59 1.364-.745a3.924 3.924 0 013.045.315c.457.254.856.597 1.17 1.008a3.785 3.785 0 011.172-1 3.924 3.924 0 013.037-.306c.5.156.963.41 1.358.746.396.336.715.748.939 1.21a3.621 3.621 0 01.087 2.965 3.704 3.704 0 01-.866 1.26"
        ></path>
      </svg>
    );
  } else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="#201F22"
          fillRule="evenodd"
          d="M3.28 2.58c-.967.443-1.676 1.495-1.676 2.75 0 1.282.525 2.27 1.277 3.117.62.697 1.37 1.276 2.101 1.84.174.134.347.267.517.4.306.243.58.455.844.61.264.153.476.224.657.224.18 0 .393-.07.657-.225.264-.154.538-.366.845-.608.169-.134.342-.267.516-.402.731-.563 1.481-1.142 2.102-1.84.752-.846 1.276-1.834 1.276-3.116 0-1.254-.709-2.307-1.677-2.75-.94-.43-2.203-.316-3.404.932a.438.438 0 01-.63 0c-1.2-1.248-2.464-1.361-3.404-.931zM7 2.603c-1.349-1.208-2.859-1.377-4.083-.818C1.625 2.377.729 3.748.729 5.33c0 1.555.648 2.742 1.498 3.698.68.766 1.512 1.407 2.248 1.973.167.128.328.253.482.375.299.235.62.486.945.676.325.19.696.344 1.098.344.403 0 .774-.154 1.098-.344.326-.19.646-.44.945-.676.16-.126.32-.251.482-.375.735-.566 1.568-1.207 2.248-1.973.85-.956 1.498-2.143 1.498-3.698 0-1.582-.896-2.954-2.188-3.544C9.86 1.226 8.35 1.395 7 2.602z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

};

IconFavorites.defaultProps = { className: null };

const { string } = PropTypes;

IconFavorites.propTypes = { className: string };

export default IconFavorites;
