import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  <svg
    className={css.iconSvg}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="#201F22"
      d="M10.518 9.693l2.498 2.498-.825.825-2.498-2.498a5.252 5.252 0 01-8.527-4.101 5.252 5.252 0 015.25-5.25 5.252 5.252 0 014.102 8.526zm-1.17-.433a4.083 4.083 0 10-7.015-2.844A4.083 4.083 0 009.26 9.349l.088-.088z"
      opacity="0.5"
    ></path>
  </svg>
);

export default IconSearchDesktop;
