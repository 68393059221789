import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  EmailShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton
} from 'react-share';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Modal,
  OutsideClickHandler,
} from '../../components';

import SocialShareIcons from './SocialShareIcons';
import ListingEmbed from './components/ListingEmbed/ListingEmbed';
import css from './SocialSharePopup.module.css';

const StarIcon = () => {
  return (
    <svg className={css.starIcon} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9676 5.08744C11.8891 4.87255 11.684 4.72747 11.4539 4.72747H7.50286L6.52547 1.30552C6.45784 1.07099 6.24404 0.909546 5.99969 0.909546C5.75644 0.909546 5.54154 1.07099 5.475 1.30552L4.49652 4.72747H0.545519C0.315353 4.72747 0.111367 4.87255 0.0328268 5.08744C-0.0457133 5.30343 0.0197368 5.5445 0.196452 5.69286L3.17007 8.17123L1.67017 12.1724C1.58618 12.3971 1.65926 12.6502 1.85016 12.7964C2.03887 12.9425 2.30394 12.9469 2.49811 12.8073L5.99969 10.3071L9.50127 12.8073C9.69653 12.9469 9.96051 12.9425 10.1503 12.7964C10.3412 12.6502 10.4132 12.3971 10.3292 12.1724L8.82932 8.17123L11.804 5.69286C11.9807 5.5445 12.0451 5.30343 11.9676 5.08744Z" fill="#000000" />
    </svg>
  )
}

const SocialSharePopup = props => {
  const { rootClassName, className, onManageDisableScrolling, listing, reviews, intl } = props;

  listing.images = listing?.images?.slice(0,1) || null;

  const classes = classNames(rootClassName || css.root, className);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);

  const shareLink = typeof window !== 'undefined' ? window.location.href : "";

  const { title = '' } = listing.attributes;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setShowSuccessPopup(true);
    setTimeout(() => setShowSuccessPopup(false), 2000);
  }

  const handleShareMsg = () => {
    const shareText = 'Check out this link: ' + shareLink;

    if (navigator.share) {
      navigator.share({ title: document.title, text: shareText, url: shareLink })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Your browser does not support the Web Share API. You can manually share the link.');
    }
  };


  const handleOpenEmbed = () => setShowEmbed(true);
  const handleCloseEmbed = () => setShowEmbed(false);

  const reviewRate = reviews.length ? (Math.round(
    (reviews.reduce((total, item) => total + item.attributes.rating, 0) / reviews.length) * 10
  ) / 10).toFixed(1) : 0;

  const reviewCount = reviews.length;

  const firstImage =
    listing?.images && listing?.images?.length > 0 ? listing.images[0] : null;

  const img = !!firstImage && firstImage?.attributes?.variants["listing-card"]?.url || null;

  const handleCloseModal = () => {
    if (showEmbed) {
      handleCloseEmbed();
    } else if (isPopupOpen) {
      setIsPopupOpen(false);
    }
  }

  const embed = `<div style="width: 150px; height: 50px; margin: auto;"><a href="${shareLink}">View on Nudge</a><a href="${shareLink}" rel="nofollow">${title} ${"&#8226;"} ${"&#9733;"} ${reviewRate}</a><script async="" src="https://www.airbnb.com/embeddable/airbnb_jssdk"></script></div>`;

  const handleCopyEmbed = () => {
    navigator.clipboard.writeText(embed);
  }

  return (
    <div className={classes}>
      <div className={css.shareWrapper} onClick={() => setIsPopupOpen(true)}>
        <SocialShareIcons type="share" /> <FormattedMessage id="SocialSharePopup.share" />
      </div>

      <Modal
        id="SocialSharePopup"
        isOpen={isPopupOpen}
        onClose={handleCloseModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={" "}
      >
        <OutsideClickHandler onOutsideClick={handleCloseModal}>
          {showEmbed
            ? (
              <ListingEmbed
                img={img}
                title={title}
                reviewRate={reviewRate}
                reviewCount={reviewCount}
                intl={intl}
                handleCloseEmbed={handleCloseEmbed}
                embed={embed}
                copyEmbed={handleCopyEmbed}
              />
            ) : (
              <>
                <h3><FormattedMessage id="SocialSharePopup.shareThisListing" /></h3>
                <div className={css.listingInfoWrapper}>
                  <div>
                    <img src={img} className={css.rootForImage} />
                  </div>
                  <p className={css.listingInfo}>
                    <span>{title}</span>
                    {" "}&#8226;{" "}
                    <StarIcon />
                    <span>{reviewRate}</span>
                  </p>
                </div>
                <div className={css.shareList}>
                  <div className={classNames(css.shareListItem, css.shareListItemText)} onClick={handleCopyToClipboard}>
                    {showSuccessPopup
                      ? <span className={css.successText}>
                        <FormattedMessage id="SocialSharePopup.copied" />
                      </span>
                      : <>
                        <SocialShareIcons type="copy" />
                        <FormattedMessage id="SocialSharePopup.copy" />
                      </>
                    }
                  </div>
                  <div className={css.shareListItem}>
                    <EmailShareButton
                      className={css.circle}
                      url={`Check out this link: ${shareLink}`}
                      resetButtonStyle={false}
                    >
                      <SocialShareIcons type="email" />
                      <span className={css.text}>
                        <FormattedMessage id="SocialSharePopup.email" />
                      </span>
                    </EmailShareButton>
                  </div>
                  <div className={classNames(css.shareListItem, css.shareListItemText)} onClick={handleShareMsg} >
                    <SocialShareIcons type="messages" />
                    <span className={css.text}>Messages</span>
                  </div>
                  <div className={css.shareListItem}>
                    <WhatsappShareButton
                      className={css.circle}
                      url={`Check out this link: ${shareLink}`}
                      resetButtonStyle={false}
                    >
                      <SocialShareIcons type="whatsApp" />
                      <FormattedMessage id="SocialSharePopup.whatsApp" />
                    </WhatsappShareButton>
                  </div>
                  {/* <div className={css.shareListItem}>
                    <FacebookMessengerShareButton
                      className={css.circle}
                      url={shareLink}
                      resetButtonStyle={false}
                    >
                      <SocialShareIcons type="messenger" />

                      Messenger
                    </FacebookMessengerShareButton>
                  </div> */}
                  <div className={css.shareListItem}>
                    <FacebookShareButton
                      className={css.circle}
                      url={shareLink}
                      resetButtonStyle={false}
                    >
                      <SocialShareIcons type="facebook" />
                      <FormattedMessage id="SocialSharePopup.facebook" />
                    </FacebookShareButton>
                  </div>
                  <div className={css.shareListItem}>
                    <TwitterShareButton
                      className={css.circle}
                      url={`Check out this link: ${shareLink}`}
                      resetButtonStyle={false}
                    >
                      <SocialShareIcons type="twitter" />
                      <FormattedMessage id="SocialSharePopup.twitter" />
                    </TwitterShareButton>
                  </div>
                  {/* <div className={css.shareListItem} onClick={handleOpenEmbed}>
                    <SocialShareIcons type="embed" />
                    <span className={css.text}>Embed</span>
                  </div> */}
                </div>
              </>
            )
          }
        </OutsideClickHandler>
      </Modal>
    </div>
  );
};

SocialSharePopup.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SocialSharePopup.propTypes = {
  rootClassName: string,
  className: string,
};

export default SocialSharePopup;
