import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmptyCart.module.css';

const IconEmptyCart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      fill="none"
      viewBox="0 0 76 76"
    >
      <path
        fill="#FFE660"
        d="M61.848 47.698H14.15c-1.23 0-2.226.997-2.226 2.226v23.85c0 1.23.997 2.226 2.226 2.226h47.698c1.23 0 2.227-.997 2.227-2.227V49.924c0-1.23-.997-2.226-2.227-2.226z"
      ></path>
      <path
        fill="#FFB602"
        d="M64.076 73.773V49.924c0-1.23-.997-2.226-2.227-2.226H38V76h23.849c1.23 0 2.227-.997 2.227-2.227z"
      ></path>
      <path
        fill="#EAEAEA"
        d="M37.999 37.842a2.226 2.226 0 01-2.227-2.226c0-.063-.026-6.407-1.73-12.604-2.08-7.567-5.563-11.404-10.352-11.404-4.28 0-6.196 2.547-6.196 5.072 0 3.453 3.432 7.169 10.965 7.169a2.227 2.227 0 010 4.453c-9.71 0-15.418-5.4-15.418-11.622 0-4.733 3.658-9.525 10.649-9.525 4.855 0 11.342 2.56 14.668 14.757 1.846 6.769 1.868 13.423 1.868 13.703 0 1.23-.997 2.227-2.227 2.227z"
      ></path>
      <path
        fill="#DBD7D7"
        d="M45.154 28.302a2.227 2.227 0 010-4.453c10.65 0 14.524-5.478 14.524-9.514 0-4.084-3.54-7.497-9.754-7.497a2.227 2.227 0 010-4.453c10.136 0 14.207 6.738 14.207 11.95 0 7.445-6.954 13.967-18.977 13.967z"
      ></path>
      <path
        fill="#201F22"
        d="M40.385 6.838c-.59 0-1.157-.234-1.575-.652L36.426 3.8A2.227 2.227 0 0139.574.652l.81.81.811-.81a2.226 2.226 0 113.149 3.149l-2.385 2.385a2.226 2.226 0 01-1.574.652z"
      ></path>
      <path
        fill="#FFB602"
        d="M75.83 37.148a2.227 2.227 0 00-2.057-1.375H49.924c-.59 0-1.157.235-1.574.653L38 46.776l-10.35-10.35a2.226 2.226 0 00-1.575-.653H2.227a2.227 2.227 0 00-1.575 3.801l11.272 11.273h52.151l11.273-11.273a2.226 2.226 0 00.482-2.426z"
      ></path>
      <path
        fill="#F98E00"
        d="M75.348 39.574a2.226 2.226 0 00-1.575-3.8H49.924c-.59 0-1.156.234-1.574.651L38 46.776v4.072h26.076l11.272-11.273z"
      ></path>
    </svg>
  );
};

IconEmptyCart.defaultProps = { className: null };

const { string } = PropTypes;

IconEmptyCart.propTypes = { className: string };

export default IconEmptyCart;
