import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes, getUserApprovedStatus, SELLER_ROLE, isBuyerRole } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconCart,
  IconFavorites
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    categoriesData
  } = props;
  const [mounted, setMounted] = useState(false);

  const isApprovedUser = getUserApprovedStatus(currentUser)
  const isBuyer = isBuyerRole(currentUser)

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            {!isBuyer ?
              <FormattedMessage id="TopbarDesktop.sellerSettingsLink" />
              : <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            }
          </NamedLink>
        </MenuItem>


        <MenuItem key="BecomeASellerPage">
          {isBuyer && (
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('BecomeASellerPage'))}
              name="BecomeASellerPage"
              to={{ state: { becomeASeller: true } }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.becomeASellerLink" />
            </NamedLink>
          )}
        </MenuItem>

        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.link}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.link}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const homeLink = (
    <NamedLink name="LandingPage" className={css.link}>
      <span className={css.linkText}>
        <FormattedMessage id="TopbarDesktop.home" />
      </span>
    </NamedLink>
  )

  const categoryLinks = categoriesData.map(c => {
    return (
      <NamedLink
        className={css.link}
        name="SearchPage"
        to={{ search: c.url }}
        key={c.labelKey}
      >
        <span className={css.linkText}>
          <FormattedMessage id={c.labelKey} />
        </span>
      </NamedLink>
    )
  })

  const favoritesLink = (
    <NamedLink className={classNames(css.link, css.linkCircle)} name="LandingPage">
      <IconFavorites />
    </NamedLink>
  );

  const cartLink = (
    <NamedLink className={classNames(css.link, css.linkCircle)} name="LandingPage">
      <IconCart />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.topBanner}>
        <FormattedMessage id="Topbar.topBanner" />
      </div>
      <div className={css.rootContent}>
        <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        {search}
        <div className={css.rootLinks}>
          {categoryLinks}
          {loginLink}
          {isApprovedUser && (
            <NamedLink
              className={classNames(css.link, css.linkButton)}
              name="NewListingPage"
            >
              <span className={css.linkText}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          )}

          {!isAuthenticatedOrJustHydrated && (
            <NamedLink
              className={classNames(css.link, css.linkButton)}
              name="SignupPage"
              to={{ search: "userRole=seller" }}
            >
              <span className={css.linkText}>
                <FormattedMessage id="TopbarDesktop.createSellerAccount" />
              </span>
            </NamedLink>
          )}
          {cartLink}
          {profileMenu}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
