export const countryOptions = [
  { key: 'afghanistan', label: 'Afghanistan' },
  { key: 'albania', label: 'Albania' },
  { key: 'algeria', label: 'Algeria' },
  { key: 'americanSamoa', label: 'American Samoa' },
  { key: 'andorra', label: 'Andorra' },
  { key: 'angola', label: 'Angola' },
  { key: 'anguilla', label: 'Anguilla' },
  { key: 'antarctica', label: 'Antarctica' },
  { key: 'antiguaAndBarbuda', label: 'Antigua and Barbuda' },
  { key: 'argentina', label: 'Argentina' },
  { key: 'armenia', label: 'Armenia' },
  { key: 'aruba', label: 'Aruba' },
  { key: 'australia', label: 'Australia' },
  { key: 'austria', label: 'Austria' },
  { key: 'azerbaijan', label: 'Azerbaijan' },
  { key: 'bahamas', label: 'Bahamas' },
  { key: 'bahrain', label: 'Bahrain' },
  { key: 'bangladesh', label: 'Bangladesh' },
  { key: 'barbados', label: 'Barbados' },
  { key: 'belarus', label: 'Belarus' },
  { key: 'belgium', label: 'Belgium' },
  { key: 'belize', label: 'Belize' },
  { key: 'benin', label: 'Benin' },
  { key: 'bermuda', label: 'Bermuda' },
  { key: 'bhutan', label: 'Bhutan' },
  { key: 'bolivia', label: 'Bolivia' },
  { key: 'bosniaAndHerzegovina', label: 'Bosnia and Herzegovina' },
  { key: 'botswana', label: 'Botswana' },
  { key: 'brazil', label: 'Brazil' },
  { key: 'bruneiDarussalam', label: 'Brunei Darussalam' },
  { key: 'bulgaria', label: 'Bulgaria' },
  { key: 'burkinaFaso', label: 'Burkina Faso' },
  { key: 'burundi', label: 'Burundi' },
  { key: 'cambodia', label: 'Cambodia' },
  { key: 'cameroon', label: 'Cameroon' },
  { key: 'canada', label: 'Canada' },
  { key: 'capeVerde', label: 'Cape Verde' },
  { key: 'caymanIslands', label: 'Cayman Islands' },
  { key: 'centralAfricanRepublic', label: 'Central African Republic' },
  { key: 'chad', label: 'Chad' },
  { key: 'chile', label: 'Chile' },
  { key: 'china', label: 'China' },
  { key: 'christmasIsland', label: 'Christmas Island' },
  { key: 'cocosIslands', label: 'Cocos (Keeling) Islands' },
  { key: 'colombia', label: 'Colombia' },
  { key: 'comoros', label: 'Comoros' },
  { key: 'congoKinshasa', label: 'Democratic Republic of the Congo (Kinshasa)' },
  { key: 'congoBrazzaville', label: 'Congo, Republic of (Brazzaville)' },
  { key: 'cookIslands', label: 'Cook Islands' },
  { key: 'costaRica', label: 'Costa Rica' },
  { key: 'coteDIvoire', label: 'Côte D\'ivoire (Ivory Coast)' },
  { key: 'croatia', label: 'Croatia' },
  { key: 'cuba', label: 'Cuba' },
  { key: 'cyprus', label: 'Cyprus' },
  { key: 'czechRepublic', label: 'Czech Republic' },
  { key: 'denmark', label: 'Denmark' },
  { key: 'djibouti', label: 'Djibouti' },
  { key: 'dominica', label: 'Dominica' },
  { key: 'dominicanRepublic', label: 'Dominican Republic' },
  { key: 'eastTimor', label: 'East Timor (Timor-Leste)' },
  { key: 'ecuador', label: 'Ecuador' },
  { key: 'egypt', label: 'Egypt' },
  { key: 'elSalvador', label: 'El Salvador' },
  { key: 'equatorialGuinea', label: 'Equatorial Guinea' },
  { key: 'eritrea', label: 'Eritrea' },
  { key: 'estonia', label: 'Estonia' },
  { key: 'ethiopia', label: 'Ethiopia' },
  { key: 'falklandIslands', label: 'Falkland Islands' },
  { key: 'faroeIslands', label: 'Faroe Islands' },
  { key: 'fiji', label: 'Fiji' },
  { key: 'finland', label: 'Finland' },
  { key: 'france', label: 'France' },
  { key: 'frenchGuiana', label: 'French Guiana' },
  { key: 'frenchPolynesia', label: 'French Polynesia' },
  { key: 'frenchSouthernTerritories', label: 'French Southern Territories' },
  { key: 'gabon', label: 'Gabon' },
  { key: 'gambia', label: 'The Gambia' },
  { key: 'georgia', label: 'Georgia' },
  { key: 'germany', label: 'Germany' },
  { key: 'ghana', label: 'Ghana' },
  { key: 'gibraltar', label: 'Gibraltar' },
  { key: 'greece', label: 'Greece' },
  { key: 'greenland', label: 'Greenland' },
  { key: 'grenada', label: 'Grenada' },
  { key: 'guadeloupe', label: 'Guadeloupe' },
  { key: 'guam', label: 'Guam' },
  { key: 'guatemala', label: 'Guatemala' },
  { key: 'guinea', label: 'Guinea' },
  { key: 'guineaBissau', label: 'Guinea-Bissau' },
  { key: 'guyana', label: 'Guyana' },
  { key: 'haiti', label: 'Haiti' },
  { key: 'holySee', label: 'Holy See' },
  { key: 'honduras', label: 'Honduras' },
  { key: 'hongKong', label: 'Hong Kong' },
  { key: 'hungary', label: 'Hungary' },
  { key: 'iceland', label: 'Iceland' },
  { key: 'india', label: 'India' },
  { key: 'indonesia', label: 'Indonesia' },
  { key: 'iran', label: 'Iran (Islamic Republic of)' },
  { key: 'iraq', label: 'Iraq' },
  { key: 'ireland', label: 'Ireland' },
  { key: 'israel', label: 'Israel' },
  { key: 'italy', label: 'Italy' },
  { key: 'ivoryCoast', label: 'Ivory Coast' },
  { key: 'jamaica', label: 'Jamaica' },
  { key: 'japan', label: 'Japan' },
  { key: 'jordan', label: 'Jordan' },
  { key: 'kazakhstan', label: 'Kazakhstan' },
  { key: 'kenya', label: 'Kenya' },
  { key: 'kiribati', label: 'Kiribati' },
  { key: 'koreaNorth', label: 'Korea (North Korea)' },
  { key: 'koreaSouth', label: 'Korea (South Korea)' },
  { key: 'kosovo', label: 'Kosovo' },
  { key: 'kuwait', label: 'Kuwait' },
  { key: 'kyrgyzstan', label: 'Kyrgyzstan' },
  { key: 'lao', label: 'Lao' },
  { key: 'latvia', label: 'Latvia' },
  { key: 'lebanon', label: 'Lebanon' },
  { key: 'lesotho', label: 'Lesotho' },
  { key: 'liberia', label: 'Liberia' },
  { key: 'libya', label: 'Libya' },
  { key: 'liechtenstein', label: 'Liechtenstein' },
  { key: 'lithuania', label: 'Lithuania' },
  { key: 'luxembourg', label: 'Luxembourg' },
  { key: 'madagascar', label: 'Madagascar' },
  { key: 'malawi', label: 'Malawi' },
  { key: 'malaysia', label: 'Malaysia' },
  { key: 'maldives', label: 'Maldives' },
  { key: 'mali', label: 'Mali' },
  { key: 'malta', label: 'Malta' },
  { key: 'marshallIslands', label: 'Marshall Islands' },
  { key: 'martinique', label: 'Martinique' },
  { key: 'mauritania', label: 'Mauritania' },
  { key: 'mauritius', label: 'Mauritius' },
  { key: 'mayotte', label: 'Mayotte' },
  { key: 'mexico', label: 'Mexico' },
  { key: 'micronesia', label: 'Micronesia' },
  { key: 'moldova', label: 'Moldova' },
  { key: 'monaco', label: 'Monaco' },
  { key: 'mongolia', label: 'Mongolia' },
  { key: 'montenegro', label: 'Montenegro' },
  { key: 'montserrat', label: 'Montserrat' },
  { key: 'morocco', label: 'Morocco' },
  { key: 'mozambique', label: 'Mozambique' },
  { key: 'myanmar', label: 'Myanmar' },
  { key: 'namibia', label: 'Namibia' },
  { key: 'nauru', label: 'Nauru' },
  { key: 'nepal', label: 'Nepal' },
  { key: 'netherlands', label: 'Netherlands' },
  { key: 'newCaledonia', label: 'New Caledonia' },
  { key: 'newZealand', label: 'New Zealand' },
  { key: 'nicaragua', label: 'Nicaragua' },
  { key: 'niger', label: 'Niger' },
  { key: 'nigeria', label: 'Nigeria' },
  { key: 'niue', label: 'Niue' },
  { key: 'northMacedonia', label: 'North Macedonia' },
  { key: 'northernMarianaIslands', label: 'Northern Mariana Islands' },
  { key: 'norway', label: 'Norway' },
  { key: 'oman', label: 'Oman' },
  { key: 'pakistan', label: 'Pakistan' },
  { key: 'palau', label: 'Palau' },
  { key: 'palestinianTerritories', label: 'Palestinian territories' },
  { key: 'panama', label: 'Panama' },
  { key: 'papuaNewGuinea', label: 'Papua New Guinea' },
  { key: 'paraguay', label: 'Paraguay' },
  { key: 'peru', label: 'Peru' },
  { key: 'philippines', label: 'Philippines' },
  { key: 'pitcairnIsland', label: 'Pitcairn Island' },
  { key: 'poland', label: 'Poland' },
  { key: 'portugal', label: 'Portugal' },
  { key: 'puertoRico', label: 'Puerto Rico' },
  { key: 'qatar', label: 'Qatar' },
  { key: 'reunionIsland', label: 'Reunion Island' },
  { key: 'romania', label: 'Romania' },
  { key: 'russianFederation', label: 'Russian Federation' },
  { key: 'rwanda', label: 'Rwanda' },
  { key: 'saintKittsAndNevis', label: 'Saint Kitts and Nevis' },
  { key: 'saintLucia', label: 'Saint Lucia' },
  { key: 'saintVincentAndTheGrenadines', label: 'Saint Vincent and the Grenadines' },
  { key: 'samoa', label: 'Samoa' },
  { key: 'sanMarino', label: 'San Marino' },
  { key: 'saoTomeAndPrincipe', label: 'Sao Tome and Principe' },
  { key: 'saudiArabia', label: 'Saudi Arabia' },
  { key: 'senegal', label: 'Senegal' },
  { key: 'serbia', label: 'Serbia' },
  { key: 'seychelles', label: 'Seychelles' },
  { key: 'sierraLeone', label: 'Sierra Leone' },
  { key: 'singapore', label: 'Singapore' },
  { key: 'slovakia', label: 'Slovakia' },
  { key: 'slovenia', label: 'Slovenia' },
  { key: 'solomonIslands', label: 'Solomon Islands' },
  { key: 'somalia', label: 'Somalia' },
  { key: 'southAfrica', label: 'South Africa' },
  { key: 'southSudan', label: 'South Sudan' },
  { key: 'spain', label: 'Spain' },
  { key: 'sriLanka', label: 'Sri Lanka' },
  { key: 'sudan', label: 'Sudan' },
  { key: 'suriname', label: 'Suriname' },
  { key: 'swaziland', label: 'Swaziland' },
  { key: 'sweden', label: 'Sweden' },
  { key: 'switzerland', label: 'Switzerland' },
  { key: 'syria', label: 'Syria' },
  { key: 'taiwan', label: 'Taiwan (Republic of China)' },
  { key: 'tajikistan', label: 'Tajikistan' },
  { key: 'tanzania', label: 'Tanzania' },
  { key: 'thailand', label: 'Thailand' },
  { key: 'tibet', label: 'Tibet' },
  { key: 'timorLeste', label: 'Timor-Leste' },
  { key: 'togo', label: 'Togo' },
  { key: 'tokelau', label: 'Tokelau' },
  { key: 'tonga', label: 'Tonga' },
  { key: 'trinidadAndTobago', label: 'Trinidad and Tobago' },
  { key: 'tunisia', label: 'Tunisia' },
  { key: 'turkey', label: 'Turkey' },
  { key: 'turkmenistan', label: 'Turkmenistan' },
  { key: 'turksAndCaicosIslands', label: 'Turks and Caicos Islands' },
  { key: 'tuvalu', label: 'Tuvalu' },
  { key: 'uganda', label: 'Uganda' },
  { key: 'ukraine', label: 'Ukraine' },
  { key: 'unitedArabEmirates', label: 'United Arab Emirates' },
  { key: 'unitedKingdom', label: 'United Kingdom' },
  { key: 'unitedStates', label: 'United States' },
  { key: 'uruguay', label: 'Uruguay' },
  { key: 'uzbekistan', label: 'Uzbekistan' },
  { key: 'vanuatu', label: 'Vanuatu' },
  { key: 'vaticanCityState', label: 'Vatican City State (Holy See)' },
  { key: 'venezuela', label: 'Venezuela' },
  { key: 'vietnam', label: 'Vietnam' },
  { key: 'virginIslandsBritish', label: 'Virgin Islands (British)' },
  { key: 'virginIslandsUS', label: 'Virgin Islands (U.S.)' },
  { key: 'wallisAndFutunaIslands', label: 'Wallis and Futuna Islands' },
  { key: 'westernSahara', label: 'Western Sahara' },
  { key: 'yemen', label: 'Yemen' },
  { key: 'zambia', label: 'Zambia' },
  { key: 'zimbabwe', label: 'Zimbabwe' },
];

export const wholesaleProductsNumbers = [
  { key: "0", label: "I do not currently sell wholesale products" },
  { key: "1-10", label: "1-10" },
  { key: "11-25", label: "11-25" },
  { key: "26-50", label: "26-50" },
  { key: "51-100", label: "51-100" },
  { key: "100+", label: "More than 100" },
];

export const workWithStoresNumbers = [
  { key: "0", label: "I am new to wholesale" },
  { key: "1-10", label: "1-10" },
  { key: "11-25", label: "11-25" },
  { key: "26-50", label: "26-50" },
  { key: "51-100", label: "51-100" },
  { key: "101-250", label: "101-250" },
  { key: "251-500", label: "251-500" },
  { key: "500-1000", label: "500-1000" },
  { key: "1000+", label: "More than 1000" },
];

export const primaryCategories = [
  { key: "jewelleryAndAccessories	", label: "Jewellery & Accessories" },
  { key: "foodAndDrink	", label: "Food & Drink" },
  { key: "homeAndLiving	", label: "Home & Decor" },
  { key: "beautyAndPersonalCare	", label: "Beauty & Personal Care" },
  { key: "fashion", label: "Fashion" },
];

export const howHearAboutUsOptions = [
  { key: "socialMedia	", label: "Social Media" },
  { key: "anotherBrand	", label: "Another Brand" },
  { key: "aRetailer	", label: "A Retailer" },
  { key: "aFaireEmployee	", label: "A Nudge Employee" },
  { key: "other	", label: "Other" },
];
