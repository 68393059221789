import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCar.module.css';

const IconCar = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === "shipping") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#201F22"
          fillRule="evenodd"
          d="M2 4.25a.75.75 0 000 1.5h10.25v10.5H9.646a2.751 2.751 0 00-5.292 0H3.75V13a.75.75 0 00-1.5 0v4c0 .414.336.75.75.75h1.354a2.751 2.751 0 005.292 0h4.708a2.751 2.751 0 005.293 0H21a.75.75 0 00.75-.75v-5.988a.748.748 0 00-.113-.408l-2.994-4.99A.75.75 0 0018 5.25h-4.25V5a.75.75 0 00-.75-.75H2zm17.646 12h.604v-4.5h-6.5v4.5h.604a2.751 2.751 0 015.293 0zM15.75 17a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zm-10 0a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zm8-6.75v-3.5h3.825l2.1 3.5H13.75z"
          clipRule="evenodd"
        ></path>
      </svg>
    )
  } else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <g clipPath="url(#clip0_141_4027)">
          <path
            fill="#201F22"
            d="M17.852 8.992l-3.026-3.126a.527.527 0 00-.379-.16h-1.933V3.43a.527.527 0 00-.527-.528H2.192a.527.527 0 00-.527.528v1.582h-.4a.527.527 0 000 1.055h.4v1.055H.527a.527.527 0 000 1.055h1.138v4.843c0 .291.236.527.527.527h1.123c.102.34.287.652.546.91.411.412.958.639 1.54.639.582 0 1.129-.227 1.54-.638.262-.262.442-.574.543-.905h5.036c.1.331.28.643.542.905.412.411.959.638 1.54.638.582 0 1.13-.227 1.54-.638.262-.262.443-.574.543-.905h.788a.527.527 0 00.527-.527V9.359a.527.527 0 00-.148-.367zm-1.624-.16H12.5V6.76h1.724l2.004 2.07zM2.72 8.178h2.744a.527.527 0 000-1.055H2.72V6.068H5.85a.527.527 0 000-1.055H2.72V3.958h8.74l-.015 8.541H7.538a2.166 2.166 0 00-.597-1.12 2.164 2.164 0 00-1.54-.639c-.582 0-1.129.227-1.54.638-.31.31-.514.695-.597 1.115H2.72V8.178zm3.475 5.535a1.116 1.116 0 01-.794.329c-.3 0-.582-.117-.794-.33a1.116 1.116 0 01-.33-.794c0-.3.117-.582.33-.794.212-.212.494-.33.794-.33a1.125 1.125 0 01.794 1.918zm9.202 0a1.116 1.116 0 01-.795.329 1.125 1.125 0 11.794-1.918c.213.212.33.494.33.794 0 .3-.117.583-.33.795zm1.342-1.214a2.166 2.166 0 00-.597-1.12 2.164 2.164 0 00-1.54-.639c-.582 0-1.129.227-1.54.638-.28.28-.467.618-.563.976V9.886h4.446V12.5h-.206z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_141_4027">
            <path fill="#fff" d="M0 0H18V18H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
  }
};

IconCar.defaultProps = { className: null };

const { string } = PropTypes;

IconCar.propTypes = { className: string };

export default IconCar;
