import React from 'react';
import { IconSuppliers } from '../../components';
import css from './FeaturedSuppliers.module.css';


export const FeaturedSuppliers = () => {

  return (
    <div className={css.root}>
      <ul className={css.brandList}>
        <li className={css.brandListItem}>
          <IconSuppliers brand="paperz" />
        </li>
        <li className={css.brandListItem}>
          <IconSuppliers brand="dorfus" />
        </li>
        <li className={css.brandListItem}>
          <IconSuppliers brand="martino" />
        </li>
        <li className={css.brandListItem}>
          <IconSuppliers brand="square" />
        </li>
        <li className={css.brandListItem}>
          <IconSuppliers brand="gobona" />
        </li>
      </ul>
    </div>
  );
}

export default FeaturedSuppliers;