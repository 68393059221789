import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCart.module.css';

const IconCart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="#201F22"
        d="M1.813 2.25h.91a.551.551 0 01.546.46l.293 1.789v.026l.876 5.25a.437.437 0 00.437.35h7a.438.438 0 00.438-.332l1.312-5.25A.438.438 0 0013.187 4H4.373l-.24-1.43a1.422 1.422 0 00-1.41-1.195h-.91a.437.437 0 100 .875zm10.814 2.625L11.534 9.25H5.247l-.73-4.375h8.11zM11 11a1.313 1.313 0 100 2.626A1.313 1.313 0 0011 11zm0 1.75a.438.438 0 110-.877.438.438 0 010 .877zM4.438 12.313a1.313 1.313 0 102.625 0 1.313 1.313 0 00-2.625 0zm1.75 0a.438.438 0 11-.876 0 .438.438 0 01.875 0z"
      ></path>
    </svg>
  );
};

IconCart.defaultProps = { className: null };

const { string } = PropTypes;

IconCart.propTypes = { className: string };

export default IconCart;
