import React, { Component } from 'react';
import Select from 'react-select';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';
import classNames from 'classnames';

import css from './FieldSelect2.module.css';

const Input = props => {
  return (
    <Field
      {...props}
      type="hidden"
      // value={value}
      component={renderProps => {
        const {
          customErrorText,
          meta,
          responseError
        } = renderProps;

        const { invalid, touched, error } = meta;
        const errorText = error || responseError;
        const hasError = (!!customErrorText || !!(touched && invalid && error)) || responseError;
        const fieldMeta = { touched: hasError, error: errorText };

        return (
          <>
            <ValidationError fieldMeta={fieldMeta} />
          </>
        )
      }}
    />
  )
}
class FieldSelect2 extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedValue) {
    const { form, name } = this.props;
    form.change(name, selectedValue)
  }

  render() {
    const {
      id,
      intl,
      className,
      form,
      label,
      disabled,
      placeholder,
      requiredMessage,
      currentUser,
      options,
      selectedOptions,
      isSubCategory,
      isMulti,
      ...rest
    } = this.props;

    const classes = classNames(
      css.root,
      className,
    );

    const selectStyle = {
      control: (provided, state) => ({
        ...provided,
        border: '1px solid #201F2233',
        borderRadius: '0px',
        boxShadow: "none",
        "&:hover": {
          border: '1px solid #201F2233',
          boxShadow: "none",
        },
        "&:focus": {
          border: '1px solid #201F2233',
          boxShadow: "none",
        }

      }),
      valueContainer: (provided, state) => ({
        ...provided,
        minHeight: '46px',
      }),
    }

    return (
      <div className={classes}>

        <label>
          {label}
        </label>

        <Select
          // defaultMenuIsOpen={true}
          className={css.root}
          isMulti={isMulti}
          autoComplete
          options={options}
          required={true}
          placeholder={placeholder}
          label={requiredMessage}
          styles={selectStyle}
          onChange={this.handleChange}
          defaultValue={!!selectedOptions ? selectedOptions : null}
        />

        <Input
          disabled={disabled}
          {...rest}
        />
      </div>
    )
  }
}

export default compose(injectIntl)(FieldSelect2);
