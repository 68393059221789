import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#201F22"
        fillRule="evenodd"
        d="M16.282 3a.75.75 0 00-1.5 0v1.25h-6.5V3a.75.75 0 00-1.5 0v1.25h-1.25A2.75 2.75 0 002.782 7v12a2.75 2.75 0 002.75 2.75h12a2.75 2.75 0 002.75-2.75V7a2.75 2.75 0 00-2.75-2.75h-1.25V3zm2.5 7.25V7c0-.69-.56-1.25-1.25-1.25h-1.25V7a.75.75 0 01-1.5 0V5.75h-6.5V7a.75.75 0 01-1.5 0V5.75h-1.25c-.69 0-1.25.56-1.25 1.25v3.25h14.5zm-14.5 1.5h14.5V19c0 .69-.56 1.25-1.25 1.25h-12c-.69 0-1.25-.56-1.25-1.25v-7.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconCalendar.defaultProps = { className: null };

const { string } = PropTypes;

IconCalendar.propTypes = { className: string };

export default IconCalendar;
